import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import Layout from './Layout'
import DatePicker from 'react-datepicker'
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { getAntrianDataByDate } from '../services/data'
import GetCookie from '../hooks/getCookie';
import { addDays } from 'date-fns';
import id from "date-fns/locale/id";



const PenarikanNik = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [errorMsg, setErrorMsg] = useState();
  const navigate = useNavigate();


  const checkToken = GetCookie('token');
  useEffect(() => {
    // refreshToken()
    if(!checkToken) navigate("/")
  }, )

  const getAllAntrianData = async () => {
    // console.log(startDate)
    const data = await getAntrianDataByDate(startDate);
    if((data.data).length > 0) {
      setErrorMsg(null);
      return exportToCSV(data.data, `Data_Antrian_${startDate}`)
    } 
    setErrorMsg("Tidak ada antrian pada tanggal yang tertera.")
  }

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], {type: fileType});
    FileSaver.saveAs(data, fileName + fileExtension);
  }

  return (
    <Layout>
      <div className="w-[80%] mx-auto">
        <h4 className="text-center my-[2rem] font-semibold text-2xl">Penarikan NIK</h4>
        <div className="my-[5rem]">
          <label className="block text-gray-700 text-xl font-bold mb-2" htmlFor="username">
            Pilih Tanggal:
          </label>
          <DatePicker 
            locale={id}
            className="sm:w-[20rem] w-full shadow-lg border-zinc-200 border-x-2 border-y-2 p-2 rounded-sm"
            selected={startDate} onChange={(date) => setStartDate(date)} 
            dateFormat="d MMMM yyyy"
              // minDate={new Date()}
              // includeDateIntervals={[{start: addDays(new Date(), 0), end: addDays(new Date(), 30) }]}
          />
          <h3 className="my-2">{errorMsg && errorMsg}</h3>
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-[7rem] my-5" type="button" onClick={(e) => getAllAntrianData()}>
            Unduh
          </button>
        </div>
      </div>
    </Layout>
  )
}

export default PenarikanNik