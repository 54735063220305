/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from 'react'
import Layout from './Layout'
import ModalTanggal from '../components/ModalTanggal'
import DatePicker from 'react-datepicker'
import id from "date-fns/locale/id";
import { getTanggalLibur } from '../services/data'
import KolomTanggalLibur from '../components/KolomTanggalLibur';
import moment from 'moment/moment';
import { useNavigate } from 'react-router-dom';
import GetCookie from '../hooks/getCookie';

const TanggalSpesial = () => {
  const [errorMsg, setErrorMsg] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [deskripsi, setDeskripsi] = useState('');
  const [dataTanggal, setDataTanggal] = useState();

  const navigate = useNavigate();


  const checkToken = GetCookie('token');
  useEffect(() => {
    // refreshToken()
    if(!checkToken) navigate("/")
  }, )

  useEffect(() => {
    getDataLibur();
  },[])

  const getDataLibur = async () => {
    // console.log(startDate)
    const data = await getTanggalLibur();
    if(data.data) {
      setErrorMsg(null);
      setDataTanggal(data);
    } 
  }

  const submitDataForm = async () => {
    console.log(moment(startDate).format())
    const formatDate = moment(startDate).format()
    const date = String(formatDate.substring(0, 10))
    console.log(date)
    const requestOptions = {
      method: 'POST',
      headers :{'Content-Type':'application/json'},
      body: JSON.stringify({
        tanggal_spesial: date,
        description: deskripsi
      })
    }
    if (!startDate) return setErrorMsg("Tanggal harus terisi.")
    else if(!deskripsi) return setErrorMsg("Deskripsi harus terisi.")
    else if(deskripsi.length > 255) return setErrorMsg("Deskripsi tidak boleh melebihi 255 karakter.")

    else {
      try{
        if (confirm("Apakah anda yakin untuk menambahkan tanggal libur ini?")) {
          const response = await fetch(process.env.REACT_APP_BPN_TANGGAL_SPESIAL, requestOptions);
          const result = await response.json();
          // console.log(result)
          if (result.statusCode === "000") {
            // navigate('/edit-jumlah-laporan/');
            window.location.reload()
          }else{
            setErrorMsg(result.message)
          }
        }
        
        
      }catch(e) {
        throw e
      }
    }
  }

  const deleteAllTanggal = async () => {
    const requestOptions = {
      method: 'DELETE',
      headers :{'Content-Type':'application/json'},
      body: JSON.stringify({
        // tanggal_spesial: date,
        // description: deskripsi
      })
    }

    try{
      if (confirm("Apakah anda yakin untuk MENGHAPUS SEMUA TANGGAL yang ada?")) {
        const response = await fetch(`${process.env.REACT_APP_BPN_TANGGAL_SPESIAL}/delete-all`, requestOptions);
        const result = await response.json();
        // console.log(result)
        if (result.statusCode === "000") {
          // navigate('/edit-jumlah-laporan/');
          window.location.reload()
        }else{
          setErrorMsg(result.message)
        }
      }
      
      
    }catch(e) {
      throw e
    }
  }


  return (
    <Layout>
      <div className="w-[80%] mx-auto my-[2rem] flex flex-col">
        <h4 className="text-center my-[2rem] font-semibold text-2xl">Tanggal Spesial</h4>
        <div className="mx-auto sm:px-0 px-5 w-full mb-5">    
        <div className={`bg-slate-50 pt-5 sm:px-20 px-5 rounded-lg my-5`}>
          <div className="mb-4">
            <p className="text-[17px] mb-2">
              Tanggal Libur Nasional
            </p>
            <DatePicker 
              locale={id}
              className="w-full border-zinc-200 border-x-2 border-y-2 p-[0.4rem] rounded-md"
              selected={startDate} onChange={(date) => setStartDate(date)} 
              dateFormat="d MMMM yyyy"
              // minDate={moment(new Date())}
              // filterDate={jenisPendaftar === 0 ? isWeekday : null}
              minDate={new Date()}
              // includeDateIntervals={[{start: addDays(new Date(), -1), end: addDays(new Date(), 30) }]}
            />
          </div>
          <div className="mt-3">
            <p className="text-[17px]">
              Deskripsi
            </p>
            <textarea className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="name" type="text" onChange={(e) => setDeskripsi(e.target.value)} placeholder="Masukkan Deskripsi..." />
          </div>
          <div className={`my-4 border rounded-sm p-3 ${errorMsg ? "block" : "hidden"}`}>
            <p className="text-red-600">{errorMsg}</p>
          </div>
          <div className="flex justify-end">
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline my-5" type="button" onClick={submitDataForm}>
              Tambah
            </button>
          </div>
        </div>
      </div>
        <div className="flex lg:flex-row flex-col ">
          <div className="overflow-x-auto relative shadow-md sm:rounded-lg mx-auto w-full">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="py-3 px-6">
                    Tanggal Libur
                  </th>
                  <th scope="col" className="py-3 px-6">
                    Keterangan
                  </th>
                  <th scope="col" className="py-3 px-6">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* <tr className="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                  <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      Apple MacBook Pro 17"
                  </th>
                  <td className="py-4 px-6">
                      Lorem ipsum dolor, sit amet consectetur adipisicing elit. Inventore, cupiditate? Necessitatibus nemo recusandae aliquid fuga dolor asperiores, dignissimos laudantium, optio ipsa, cumque consequuntur? Quibusdam, consequuntur? Quibusdam aut commodi provident excepturi?
                  </td>
                  <td className="py-4 px-6">
                      <a href="#" className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Edit</a>
                  </td>
                </tr>
                <tr className="bg-gray-50 border-b dark:bg-gray-800 dark:border-gray-700">
                    <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        Microsoft Surface Pro
                    </th>
                    <td className="py-4 px-6">
                        White
                    </td>
                    <td className="py-4 px-6">
                        <a href="#" className="font-medium text-blue-600 dark:text-blue-500 hover:underline">Edit</a>
                    </td>
                </tr> */}

                {dataTanggal?.data === undefined ? 
                  'data kosong' : ''
                }

                {dataTanggal?.data?.map((e) => {
                  return (<KolomTanggalLibur data={e}/>)
                })}
              </tbody>
            </table>
            {
              dataTanggal?.data?.length > 0 ? (<div className="flex justify-end">
              <button className="bg-red-500 hover:bg-red-700 text-white font-light py-2 px-4 rounded focus:outline-none focus:shadow-outline my-5 mr-5" onClick={deleteAllTanggal}>Delete All</button>
            </div>) : ''
            }
          </div>
        </div>
        {/* <ModalTanggal /> */}
      </div>
    </Layout>
  )
}

export default TanggalSpesial