/* eslint-disable no-restricted-globals */
import Layout from './Layout'
import React, { useRef } from 'react'
import { useLocation } from 'react-router-dom';
import { antrianPendaftaran, antrianPengambilan } from '../hardcode/dataLayananChart'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ETicket from '../components/ETicket';
import { useReactToPrint } from "react-to-print";

const AntrianSuccess = () => {
  const [showAlert, setShowAlert] = React.useState(true);

  const navigate = useNavigate();
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Antrian Layanan BPN Tangsel"
  });
  const { state } = useLocation();
  // const { antrian_id, booking_time, email, nama, nik, no_antrian, no_telp, regist_id, take_id } = state;
  // console.log(antrian_id)
  // console.log(state)
  // const cariNamaPendaftaran = antrianPendaftaran.filter((e) => {
  //   return e.id === regist_id
  // })
  // const cariNamaPengambilan = antrianPengambilan.filter((e) => {
  //   return e.id === take_id
  // })
  // console.log(cariNamaPendaftaran, cariNamaPengambilan)

  return (
    <Layout>
      <div>
        {showAlert ? (
          <div
            className={
              "text-white px-6 py-4 border-0 rounded relative mb-4 bg-green-500"
            }
          >
            <span className="inline-block align-middle mr-8">
              <b className="capitalize">Antrian berhasil terdaftar!</b> Silahkan Screenshot / Unduh E-Tiket berikut untuk bukti pendaftaran.
            </span>
            <button
              type="button"
              className="bg-green-600 hover:bg-green-700 p-2 rounded-md lg:mt-0 mt-3"
              onClick={handlePrint}
            >
              Unduh E-Tiket
            </button>
            {/* <button
              className="absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-4 mr-6 outline-none focus:outline-none"
              onClick={() => setShowAlert(false)}
            >
              <span>×</span>
            </button> */}
          </div>
        ) : null}
      </div>
      <ETicket props={state} ref={componentRef} />
      <div class="md:w-[50rem] w-[80%] md:mx-auto mx-5 mb-28 mt-5 flex sm:flex-row flex-col">
        <button class="bg-slate-500 hover:bg-slate-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-[7rem]" 
        onClick={() => {
          if (confirm("Apakah anda sudah unduh/screenshot E-Tiket anda?")){
            navigate('/layanan')
          }
        }}
        
        >
          Selesai
        </button>
        <button
          type="button"
          className="bg-slate-500 hover:bg-slate-700 text-white font-bold w-[10rem] sm:ml-3 ml-0 sm:mt-0 mt-3 py-2 px-4 rounded-md"
          onClick={handlePrint}
        >
          Unduh E-Tiket
        </button>
      </div>
			{/* <div class="content-wrap bg-light flex justify-center">
				<div class="sm:w-[50rem] w-full my-28 shadow border rounded bg-slate-50">
					<div class="row justify-content-center">
						<div class="col-lg-7 col-md-10">
							<div class="card my-5 shadow-sm">
								<div class="card-body p-5">
                  <div class="col-sm-3 center">
                    <img src={require("../images/bpn-logo.png")} className="w-[12rem] ml-5" alt="bpn_tangsel" />
                  </div>

									<h2 class="font-body uppercase text-center text-3xl font-semibold">E-Tiket</h2>
									<h4 class="font-body text-uppercase text-center text-xl font-medium my-6">Layanan BPN Tangsel</h4>
									<h6 class="font-secondary text-center text-sm font-light"> Melayani, Profesional, Terpecaya </h6>

                  <div className="flex justify-center my-10 items-center">
                    <hr className="w-[10rem]" />

                    <div className="bg-slate-300 w-[1rem] h-[1rem] rounded-full mx-6"></div>

                    <hr className="w-[10rem]" />
                  </div>
									
									<div class="form-widget w-full" data-alert-type="false">

										<div class="form-process">
											<div class="css3-spinner">
												<div class="css3-spinner-double-bounce1"></div>
												<div class="css3-spinner-double-bounce2"></div>
											</div>
										</div>

                    <div class="row mx-auto">
                      <div class="col-12 center mb-5">
                        <h4 class="font-body text-uppercase text-center text-xl font-medium">Antrian</h4>
                        <h4 class="font-secondary text-uppercase text-center text-xl font-medium my-10">{no_antrian}</h4>
                        <h4 className="font-body text-uppercase text-center text-xl font-medium">Save your Date</h4>
                        <h4 className="font-secondary text-uppercase text-center text-xl font-medium my-10">{booking_time}</h4>
                      </div>
                      <div className="col-12 w-full">
                        <div className="row">
                          <table className="w-full md:ml-20 sm:ml-10 ml-5">
                            <tbody>
                            <tr>
                              <td>Nama</td>
                              <td>:</td>
                              <td>{nama}</td>
                            </tr>
                            <tr><td>NIK</td>
                              <td>:</td>
                              <td>{nik}</td>
                            </tr>
                            <tr><td>Nomor Telepon</td>
                              <td>:</td>
                              <td>{no_telp}</td>
                            </tr>
                            <tr><td>Email</td>
                              <td>:</td>
                              <td>{email}</td>
                            </tr>
                            <tr><td>Layanan</td>
                              <td>:</td>
                              <td>{cariNamaPendaftaran[0]?.nama}</td>
                            </tr>
                            <tr>
                              <td></td>
                              <td></td>
                              <td>{cariNamaPengambilan[0]?.nama}</td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="col-12 mt-10">
                        <button class="bg-slate-500 hover:bg-slate-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full mt-2" onClick={() => navigate('/layanan')}>Selesai</button>
                      </div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div> */}
    </Layout>
    
  )
}

export default AntrianSuccess