import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode'
import Layout from './Layout'
import DatePicker from 'react-datepicker'
import Select from 'react-select'
import BarChartInfo from '../components/BarChartInfo';
import { antrianPendaftaran, antrianPengambilan } from "../hardcode/dataLayananChart"
import { getAntrianDataByDate, getAntrianDataByDateRange } from '../services/data'
import axios from 'axios'
import GetCookie from '../hooks/getCookie'
import id from "date-fns/locale/id";
// import BarChartInfo from '../components/BarChartInfo';


const Dashboard = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [optionLayanan, setOptionLayanan] = useState("");
  const [dataRecap, setDataRecap] = useState();
  const [errorMsg, setErrorMsg] = useState();
  const navigate = useNavigate();

  const [token, setToken] = useState('')

  const checkToken = GetCookie('token');
  useEffect(() => {
    // refreshToken()
    if(!checkToken) navigate("/")
  }, )

  useEffect(() => {

  }, [optionLayanan])

  // const refreshToken = async () => {
  //   try {
  //     const response = await axios.get(`${process.env.REACT_APP_BPN_ADMIN_USERS}/token`)
  //     setToken(response.data.accessToken);
  //     const decoded = jwtDecode(response.data.accessToken)
  //     console.log(decoded);
  //   } catch (error) {
      
  //   }
  // }

  const getAllAntrianData = async () => {
    // console.log(startDate, endDate)
    // if(!startDate || !endDate || !optionLayanan) setErrorMsg("Mohon lengkapi kolom yang tersedia.")
    // else{
    //   const data = await getAntrianDataByDateRange(optionLayanan, new Date(startDate), new Date(endDate));
    //   console.log(data)
    //   if (!data) setErrorMsg("Data tidak ditemukan")
    //   else{
    //     setDataRecap(data.data)
    //   }
    // }
    const data = await getAntrianDataByDateRange(optionLayanan, new Date(startDate), new Date(endDate));
    setDataRecap(data.data)
    if (data.statusCode === "001") setErrorMsg(data.message)
  }

  const optionsJenisLayanan = [
    {
      value: "R",
      label: "Pendaftaran"
    },
    {
      value: "T",
      label: "Pengambilan"
    }
  ]

  const handleJenisLayananChange = value => {
    try {
      setOptionLayanan(value.value);
    } catch (error) {
      console.log(error);
    }
  };
  
  return (
    <Layout>
      <div className="w-[80%] mx-auto">
      <h4 className="text-center mb-[2rem] mt-[4rem] font-semibold text-2xl">Dashboard Admin</h4>
      <div className="my-10">
        <label className="block text-gray-700 text-xl font-bold mb-2">
          Pilih Jenis Pengambilan
        </label>
        <Select
          className="sm:w-[20rem] w-full shadow-lg"
          onChange={handleJenisLayananChange}
          options={optionsJenisLayanan}
        />
      </div>
        <div className="my-[2rem]">
          <label className="block text-gray-700 text-xl font-bold mb-2">
            Pilih Tanggal:
          </label>
          <div className="flex lg:flex-row flex-col lg:w-[50rem] md:w-[40rem]">
            <div>
              <h2>Dari:</h2>
              <DatePicker
                locale={id}
                className="sm:w-[20rem] w-full shadow-lg border-zinc-200 border-x-2 border-y-2 p-2 rounded-sm"
                selected={startDate}
                // onChange={(date) => setStartDate(date)}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                dateFormat="d MMMM yyyy"
              />
            </div>
            <div className="lg:ml-5 ml-0 lg:mt-0 mt-5">
              <h2>Sampai:</h2>
              <DatePicker
                locale={id}
                className="sm:w-[20rem] w-full shadow-lg border-zinc-200 border-x-2 border-y-2 p-2 rounded-sm"
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                dateFormat="d MMMM yyyy"
              />
            </div>
            
          </div>
          
          <h3 className={`my-2`}>{errorMsg}</h3>
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-[7rem] my-5" type="button" onClick={(e) => getAllAntrianData()}>
            Cari
          </button>
        </div>
      </div>
      <div className="lg:w-[70rem] w-[90%]">
        <BarChartInfo data={dataRecap} />
      </div>
      
    </Layout>
  )
}

export default Dashboard