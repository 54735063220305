export let antrianPendaftaran = [
  {
    id: "R1",
    nama: "Pendaftaran Pengukuran",
    count: 0
  },
  {
    id: "R2",
    nama: "Pendaftaran Pengakuan Hak / Pendaftaran Pertama Kali",
    count: 0
  },
  {
    id: "R3",
    nama: "Pendaftaran Hak Milik Sarusun",
    count: 0
  },
  {
    id: "R4",
    nama: "Pendaftaran Tanah Wakaf",
    count: 0
  },
  {
    id: "R5",
    nama: "Pendaftaran Peralihan Hak",
    count: 0
  },
  {
    id: "R6",
    nama: "Pendaftaran Pemindahan Hak",
    count: 0
  },
  {
    id: "R7",
    nama: "Pendaftaran Perubahan Hak",
    count: 0
  },
  {
    id: "R8",
    nama: "Pendaftaran Pemecahan/Penggabungan Hak",
    count: 0
  },
  {
    id: "R9",
    nama: "Pendaftaran SK",
    count: 0
  },
  {
    id: "R10",
    nama: "Pendaftaran Blokir",
    count: 0
  },
  {
    id: "R11",
    nama: "Pendaftaran Pengapusan Hak Tanggungan / Roya",
    count: 0
  },
  {
    id: "R12",
    nama: "Pendaftaran Surat Keterangan Pendaftaran Tanah ( SKPT )",
    count: 0
  },
  {
    id: "R13",
    nama: "Pendaftaran Penerbitan Sertipikat Pengganti ( Ganti Blanko / Sertipikat Hilang )",
    count: 0
  },
]

export let antrianPengambilan = [
  {
    id: "T1",
    nama: "Pengambilan Pengukuran",
    count: 0
  },
  {
    id: "T2",
    nama: "Pengambilan Pengakuan Hak / Pendaftaran Pertama Kali",
    count: 0
  },
  {
    id: "T3",
    nama: "Pengambilan Hak Milik Sarusun",
    count: 0
  },
  {
    id: "T4",
    nama: "Pengambilan Tanah Wakaf",
    count: 0
  },
  {
    id: "T5",
    nama: "Pengambilan Peralihan Hak",
    count: 0
  },
  {
    id: "T6",
    nama: "Pengambilan Pemindahan Hak",
    count: 0
  },
  {
    id: "T7",
    nama: "Pengambilan Perubahan Hak",
    count: 0
  },
  {
    id: "T8",
    nama: "Pengambilan Pemecahan/Penggabungan Hak",
    count: 0
  },
  {
    id: "T9",
    nama: "Pengambilan SK",
    count: 0
  },
  {
    id: "T10",
    nama: "Pengambilan Blokir",
    count: 0
  },
  {
    id: "T11",
    nama: "Pengambilan Pengapusan Hak Tanggungan / Roya",
    count: 0
  },
  {
    id: "T12",
    nama: "Pengambilan Surat Keterangan Pendaftaran Tanah ( SKPT )",
    count: 0
  },
  {
    id: "T13",
    nama: "Pengambilan Penerbitan Sertipikat Pengganti ( Ganti Blanko / Sertipikat Hilang )",
    count: 0
  },
]

export let antrianPendaftaranPengambilan = [
  {
    id: "R1",
    nama: "Pendaftaran Pengukuran",
    count: 0
  },
  {
    id: "R2",
    nama: "Pendaftaran Pengakuan Hak / Pendaftaran Pertama Kali",
    count: 0
  },
  {
    id: "R3",
    nama: "Pendaftaran Hak Milik Sarusun",
    count: 0
  },
  {
    id: "R4",
    nama: "Pendaftaran Tanah Wakaf",
    count: 0
  },
  {
    id: "R5",
    nama: "Pendaftaran Peralihan Hak",
    count: 0
  },
  {
    id: "R6",
    nama: "Pendaftaran Pemindahan Hak",
    count: 0
  },
  {
    id: "R7",
    nama: "Pendaftaran Perubahan Hak",
    count: 0
  },
  {
    id: "R8",
    nama: "Pendaftaran Pemecahan/Penggabungan Hak",
    count: 0
  },
  {
    id: "R9",
    nama: "Pendaftaran SK",
    count: 0
  },
  {
    id: "R10",
    nama: "Pendaftaran Blokir",
    count: 0
  },
  {
    id: "R11",
    nama: "Pendaftaran Pengapusan Hak Tanggungan / Roya",
    count: 0
  },
  {
    id: "R12",
    nama: "Pendaftaran Surat Keterangan Pendaftaran Tanah ( SKPT )",
    count: 0
  },
  {
    id: "R13",
    nama: "Pendaftaran Penerbitan Sertipikat Pengganti ( Ganti Blanko / Sertipikat Hilang )",
    count: 0
  },
  {
    id: "R14",
    nama: "Pendaftaran Ploting dan Validasi",
    count: 0
  },
  {
    id: "R15",
    nama: "Pendaftaran lain-lain",
    count: 0
  },
  {
    id: "T1",
    nama: "Pengambilan Pengukuran",
    count: 0
  },
  {
    id: "T2",
    nama: "Pengambilan Pengakuan Hak / Pendaftaran Pertama Kali",
    count: 0
  },
  {
    id: "T3",
    nama: "Pengambilan Hak Milik Sarusun",
    count: 0
  },
  {
    id: "T4",
    nama: "Pengambilan Tanah Wakaf",
    count: 0
  },
  {
    id: "T5",
    nama: "Pengambilan Peralihan Hak",
    count: 0
  },
  {
    id: "T6",
    nama: "Pengambilan Pemindahan Hak",
    count: 0
  },
  {
    id: "T7",
    nama: "Pengambilan Perubahan Hak",
    count: 0
  },
  {
    id: "T8",
    nama: "Pengambilan Pemecahan/Penggabungan Hak",
    count: 0
  },
  {
    id: "T9",
    nama: "Pengambilan SK",
    count: 0
  },
  {
    id: "T10",
    nama: "Pengambilan Blokir",
    count: 0
  },
  {
    id: "T11",
    nama: "Pengambilan Pengapusan Hak Tanggungan / Roya",
    count: 0
  },
  {
    id: "T12",
    nama: "Pengambilan Surat Keterangan Pendaftaran Tanah ( SKPT )",
    count: 0
  },
  {
    id: "T13",
    nama: "Pengambilan Penerbitan Sertipikat Pengganti ( Ganti Blanko / Sertipikat Hilang )",
    count: 0
  },
  {
    id: "T14",
    nama: "Pengambilan Ploting dan Validasi",
    count: 0
  },
  {
    id: "T15",
    nama: "Pengambilan lain-lain",
    count: 0
  },
]