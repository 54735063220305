exports.harusDisiapkan = {
  id: 1,
  title: "PERSIAPAN",
  subTitle: "Hal - hal yang harus dipersiapkan sebelum memulai Laporan Pengaduan",
  description: "Pastikan Sebelum Bertanya tindakan Hukum nya Harus jelas Berdasarkan Peranturan Pemerintah No 8/2018 dan 21/2021 untuk pelayanan pengaduan",
  listCara: [
    {
      cara: "Lampirkan data data pendukung (Informasi)"
    },
    {
      cara: "Bukti tanda terima yang di keluarkan oleh pihak ATR/BPN Tangerang Selatan"
    }
  ]
}

exports.caraPertama = {
  id: 2,
  title: "LANGKAH PERTAMA",
  description: "Setelah Customer selesai mempersiapkan berkas persyaratan, Customer langsung dapat menghubungi petugas pengaduan melalui tatap muka atau datang langsung ke kantor/ aplikasi lapor / wa / email / sosial media atpr bpk tangsel.",
}

exports.caraKedua = {
  id: 3,
  title: "LANGKAH KEDUA",
  description: "Setelah laporan pengaduan diterima oleh petugas pengaduan, petugas akan memberikan feedback atau respon kepada pelapor dimana jika semua persyaratan telah terpenuhi berkas akan langsung di serahkan oleh petugas pengaduan kepada tim petugas pengaduan dengan estimasi waktu kurang lebih 1 hari.",
}

exports.caraKetiga = {
  id: 4,
  title: "LANGKAH KETIGA",
  description: "Setelah laporan sampai di tim petugas pengaduan, laporan akan di proses dan di tindak lanjut oleh pejabat pengawas dengan estimasi waktu kurang lebih 2 hari.",
}

exports.caraKeempat = {
  id: 5,
  title: "LANGKAH KEEMPAT",
  description: "Pejabat pengawas akan menyerahkan hasil laporan yang telah di proses kepada tim petugas pengaduan untuk di cek kembali, jika pengaduan sudah selesai tim akan menyerahkan Informasi kepada petugas pengaduan yang nantinya akan di serahkan kepada Customer. Dan laporan pengaduan telah selesai.",
}