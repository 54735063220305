import { motion } from "framer-motion";
import React from "react";
import { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import GetCookie from "../hooks/getCookie";
import RemoveCookie from "../hooks/removeCookie";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  } from '@fortawesome/free-regular-svg-icons'
import { faBars, faRectangleXmark } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faInstagram, faTwitter, faYoutube, faWhatsapp, faGoogle  } from '@fortawesome/free-brands-svg-icons'

//Framer Variants
const login = {
  hover: {
    backgroundColor: "#FF0049",
    color: "#FFFFFF",
    borderColor: "#FFFFFF",
    transition: {
      duration: 0.3,
    },
  },
  start: {
    backgroundColor: "#FFFFFF",
    color: "#FF0049",
    borderColor: "#FF0049",
  },
};

const register = {
  hover: {
    backgroundColor: "#FFFFFF",
    color: "#FF0049",
    borderColor: "#FF0049",
    transition: {
      duration: 0.3,
    },
  },
  start: {
    backgroundColor: "#FF0049",
    color: "#FFFFFF",
    borderColor: "#FFFFFF",
  },
};

const menu = {
  hover: {
    x:100,
    // scale:"1",
    color: "#1ABC9C",
    // textDecoration: "underline",
    // textUnderlineOffset: "6px",
    // textDecorationThickness: "2px",
    transition: {
      duration: 0.3,
    },
  },
};

const Navbar = () => {
  // const [data, setData] = useState([])
  const [navOpen, setNavOpen] = useState(false);
  const location = useLocation();
  const checkToken = GetCookie('token')
  //get user context
  useEffect(() => {
  }, [checkToken])


  return (
    <div className="font-navbar sticky top-0 z-50 bg-white p-[1rem] shadow-lg shadow-black-500/50">
      <nav className="flex justify-between font-bold transition-all duration-500 ease-in">
        <div className="sm:flex">
          <a href="/">
            <img
              className="mr-4 w-[14rem]"
              src={require("../images/bpn-logo.png")}
              alt="bpn_tangsel"
            ></img>
          </a>
        </div>

        <div
          onClick={() => {
            setNavOpen(!navOpen);
          }}
          className="text-2xl absolute right-8 top-10 cursor-pointer lg:hidden"
        >
          <FontAwesomeIcon icon={navOpen ? faRectangleXmark : faBars} />
          {/* <FontAwesomeIcon icon={faRectangleXmark}/> */}

        </div>
        <ul
          // className={`lg:flex lg:flex-row flex flex-col lg:items-center mx-5 lg:pl-0 pl-12 lg:pb-0 pb-10 text-[#878787] text-[1rem] absolute lg:static bg-white lg:z-auto z-[-1] -left-5 w-full lg:w-auto"
          //   }`}
          className={`lg:flex lg:flex-row flex flex-col lg:items-center mx-5 lg:pl-0 pl-12 lg:pb-0 pb-10 text-[#878787] text-[1rem] absolute lg:static bg-white lg:z-auto z-[-1] -left-5 w-full lg:w-auto ${
            navOpen ? "top-[6rem]" : "top-[-1000px]"
          }`
}
        >
          {
            (GetCookie('token') !== undefined) ?  
              <>
                <li className="lg:mx-3 lg:my-0 my-5 text-green-700">
                  <motion.div whileHover={{ scale: 1.1 }}>
                    <motion.a
                      href="./dashboard-admin"
                      whileHover="hover"
                      variants={menu}
                    >
                      Dashboard
                    </motion.a>
                  </motion.div>  
                </li>
                <li className="lg:mx-3 lg:my-0 my-5 text-green-700">
                  <motion.div whileHover={{ scale: 1.1 }}>
                    <motion.a
                      href="./penarikan-data-nik"
                      whileHover="hover"
                      variants={menu}
                    >
                      Penarikan Data
                    </motion.a>
                  </motion.div>  
                </li>
                <li className="lg:mx-3 lg:my-0 my-5 text-green-700">
                  <motion.div whileHover={{ scale: 1.1 }}>
                    <motion.a
                      href="./upload-surat-kuasa"
                      whileHover="hover"
                      variants={menu}
                    >
                      Upload Surat
                    </motion.a>
                  </motion.div>  
                </li>
                <li className="lg:mx-3 lg:my-0 my-5 text-green-700">
                  <motion.div whileHover={{ scale: 1.1 }}>
                    <motion.a
                      href="./edit-jumlah-laporan"
                      whileHover="hover"
                      variants={menu}
                    >
                      Jumlah Laporan
                    </motion.a>
                  </motion.div>  
                </li>
                <li className="lg:mx-3 lg:my-0 my-5 text-green-700">
                  <motion.div whileHover={{ scale: 1.1 }}>
                    <motion.a
                      href="./tanggal-spesial"
                      whileHover="hover"
                      variants={menu}
                    >
                      Tanggal Libur
                    </motion.a>
                  </motion.div>  
                </li>
                <li className="lg:mx-3 lg:my-0 my-5 text-green-700">
                  <motion.div whileHover={{ scale: 1.1 }}>
                    <motion.a
                      href="./tambah-user-admin"
                      whileHover="hover"
                      variants={menu}
                    >
                      Daftar
                    </motion.a>
                  </motion.div>  
                </li>
                <li className="lg:mx-3 lg:my-0 my-5 text-green-700">
                  <motion.div whileHover={{ scale: 1.1 }}>
                    <motion.a
                      onClick={() => RemoveCookie('token')}
                      href="/"
                      whileHover="hover"
                      variants={menu}
                    >
                      Logout
                    </motion.a>
                  </motion.div>  
                </li>
              </>
            : 
            <>
              <li className="lg:mx-2 lg:my-0 my-2 text-green-700">
                <motion.div whileHover={{ scale: 1.1 }}>
                  <motion.a
                    href="/"
                    whileHover="hover"
                    variants={menu}
                  >
                    Home
                  </motion.a>
                </motion.div>
              </li>
              <li className="lg:mx-2 lg:my-0 my-2 text-green-700">      
                <motion.div whileHover={{ scale: 1.1 }}>
                  <motion.a
                    href="./about"
                    whileHover="hover"
                    variants={menu}
                  >
                    Profil
                  </motion.a>
                </motion.div>
              </li>
              <li className="lg:mx-2 lg:my-0 my-2 text-green-700">
                <motion.div whileHover={{ scale: 1.1 }}>
                  <motion.a
                    href="./layanan"
                    whileHover="hover"
                    variants={menu}
                  >
                    Layanan
                  </motion.a>
                </motion.div>   
              </li>
              {
                (location.pathname === '/') ? 
                  <li className="lg:mx-3 lg:my-0 my-2 text-green-700">
                    <motion.div whileHover={{ scale: 1.1 }}>
                      <motion.a
                        href="./login"
                        whileHover="hover"
                        variants={menu}
                      >
                        Login
                      </motion.a>
                    </motion.div>  
                  </li>
                : ''
              }
            </>
          }
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
