import Layout from './Layout'
import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import { antrianPendaftaran, antrianPengambilan, antrianPendaftaranPengambilan } from '../hardcode/dataLayananChart'
import { useNavigate } from 'react-router-dom';
import { getAntrianDataById } from '../services/data'
import { QRCodeSVG } from 'qrcode.react';
import axios from 'axios';

const CheckAntrian = () => {
  const [cekAntrian, setCekAntrian] = useState();
  const navigate = useNavigate();
  const { id } = useParams();

  // let stringKodePengambilan = ''

  useEffect(() => {
    getAllLayananData(id);
  },[id])

  const getAllLayananData = async (antrian_id) => {
    const data = await getAntrianDataById(antrian_id);
    setCekAntrian(data)
  }
  let jenisPendaftar = cekAntrian?.data?.jenis_pendaftar === 0 ? "Kuasa" : cekAntrian?.data?.jenis_pendaftar === 1 ? "Pemohon Langsung" : null ;
  

  console.log(cekAntrian)
  if (cekAntrian?.statusCode !== "001") {
    let transaction = cekAntrian?.data?.transaction
    if( transaction !== undefined) {
      for (let v of transaction) {
        const cariNamaAntrian = antrianPendaftaranPengambilan.filter((e) => {
          return e.id === v.layanan_type
        })
        v.layanan_name = cariNamaAntrian[0]?.nama
        // if (v.layanan_type.includes("T")) {

        //   for (let i of v.kode_pengambilan) {
        //     // console.log(i)
        //     if (stringKodePengambilan !== '') stringKodePengambilan += `, ${i}`
        //     else stringKodePengambilan += i
        //   }
        // }
      }
    }
  }  

  return (
    <Layout>
      <h2 className="text-xl my-10 text-center">Hasil Cek Antrian: <span className={`${cekAntrian?.statusCode === '000' ? 'text-green-500' : 'text-red-600'}`}>{cekAntrian?.message}</span></h2>
			<div className={`content-wrap bg-light flex justify-center ${cekAntrian?.statusCode === '000' ? 'block' : 'hidden'}`}>
				<div class="sm:w-[50rem] w-full mb-10 shadow border rounded bg-slate-50">
					<div class="row justify-content-center">
						<div class="col-lg-7 col-md-10">
							<div class="card my-5 shadow-sm">
								<div class="card-body p-5">
                  <div class="col-sm-3 center">
                    <img src={require("../images/bpn-logo.png")} className="w-[12rem] ml-5" alt="bpn_tangsel" />
                  </div>

									<h2 class="font-body uppercase text-center text-3xl font-semibold">E-Tiket</h2>
									<h4 class="font-body text-uppercase text-center text-xl font-medium my-6">Layanan BPN Tangsel</h4>
									<h6 class="font-secondary text-center text-sm font-light"> Melayani, Profesional, Terpecaya </h6>

                  <div className="flex justify-center my-10 items-center">
                    <hr className="w-[10rem]" />

                    <div className="bg-slate-300 w-[1rem] h-[1rem] rounded-full mx-6"></div>

                    <hr className="w-[10rem]" />
                  </div>
									
									<div class="form-widget w-full" data-alert-type="false">

										<div class="form-process">
											<div class="css3-spinner">
												<div class="css3-spinner-double-bounce1"></div>
												<div class="css3-spinner-double-bounce2"></div>
											</div>
										</div>

                    <div class="row mx-auto">
                      <div class="col-12 center mb-5">
                        <h4 class="font-body text-uppercase text-center text-xl font-medium">Antrian</h4>
                        <h4 class="font-secondary text-uppercase text-center text-xl font-medium my-10">{cekAntrian?.data?.no_antrian}</h4>
                        <h4 className="font-body text-uppercase text-center text-xl font-medium">Save your Date</h4>
                        <h4 className="font-secondary text-uppercase text-center text-xl font-medium my-10">{cekAntrian?.data?.booking_time}</h4>
                      </div>
                      <div className="col-12 w-full">
                        <div className="row">
                          <table className="w-[80%] md:ml-20 sm:ml-10 ml-5">
                            <tbody>
                            <tr>
                              <td>Nama</td>
                              <td>:</td>
                              <td>{cekAntrian?.data?.nama}</td>
                            </tr>
                            <tr><td>NIK</td>
                              <td>:</td>
                              <td>{cekAntrian?.data?.nik}</td>
                            </tr>
                            <tr><td>Nomor Telepon</td>
                              <td>:</td>
                              <td>{cekAntrian?.data?.no_telp}</td>
                            </tr>
                            <tr><td>Email</td>
                              <td>:</td>
                              <td>{cekAntrian?.data?.email}</td>
                            </tr>
                            <tr>
                              <td>Jenis Pendaftar</td>
                              <td>:</td>
                              <td>{jenisPendaftar}</td>
                            </tr>
                            <tr><td>Layanan</td>
                              <td>:</td>
                              {/* <td>{cariNamaPendaftaran[0]?.nama}</td> */}
                            </tr>
                            {cekAntrian?.data?.transaction?.map((e) => {
                              let stringKodePengambilan = ''
                              if (e.layanan_type.includes("T") && e.kode_pengambilan !== null) {
                                console.log(e)
                                for (let i of e?.kode_pengambilan) {
                                  // console.log(i)
                                  if (stringKodePengambilan !== '') stringKodePengambilan += `, ${i}`
                                  else stringKodePengambilan += i
                                }
                              }
                              return (
                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td>- {e?.layanan_name} <strong>({e?.quantity === 0 ? stringKodePengambilan : e?.quantity})</strong></td>
                                </tr>
                              )
                            })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="col-12 mt-10">
                        <button class="bg-slate-500 hover:bg-slate-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full mt-2" onClick={() => navigate('/')}>Selesai</button>
                      </div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
    </Layout>
    
  )
};

export default CheckAntrian