import React,{Component} from 'react';
import Slider from "react-slick";

const images = [
  {
      id:1,
      url: require('../images/kantor-bpn.jpeg')
  },
  {
      id:2,
      url: require('../images/atr-bpn-tangsel.JPG')
  },
  {
      id:3,
      url:require('../images/bpn-zoom.png')
      // url: require(`https://drive.google.com/uc?export=view&id=1b2TqpvH0S9g9fBVvswYv9cMvNkpariY7`)
  },
  {
    id:4,
    url:require('../images/bpn_zona_integritas.jpg')
  }
]

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style=
        {
          { 
            ...style, 
            display: "block", 
            position: "absolute",
            right: "0.5rem",
            zIndex: "100"
          }
        }
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style=
        {
          { 
            ...style, 
            display: "block", 
            position: "absolute",
            left: "0.5rem",
            zIndex: "100"
          }
        }
      onClick={onClick}
    />
  );
}

class ImageSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />
    };
    return (
      <div className="bg-[#fefefe]">
        <Slider {...settings}>
          {images.map((image) => {
            return (
              <div className="h-auto max-h-[70vh] grid">
                  <img key={image.id} src={image.url} width="100%" alt='' className="object-fit" />
              </div>
            )
          })}
        </Slider>
        {/* <img src={require("../images/atr-bpn-tangsel.JPG")} alt="" /> */}
      </div>
    );
  }
}

export default ImageSlider
