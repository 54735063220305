import React, { useState, useEffect } from 'react'
import Layout from './Layout'
import { useNavigate } from 'react-router-dom';
import GetCookie from '../hooks/getCookie';

const TambahUserAdmin = () => {
  const [nama, setNama] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confPassword, setConfPassword] = useState("");
  const [msgError, setMsgError] = useState('');
  const navigate = useNavigate();
  const checkToken = GetCookie('token');
  useEffect(() => {
    // refreshToken()
    if(!checkToken) navigate("/")
  }, )

  const submitDataForm = async () => {
    try {
      const requestOptions = {
        method: 'POST',
        headers :{'Content-Type':'application/json'},
        body: JSON.stringify({
          nama: nama,
          email: email,
          password: password,
          confPassword: confPassword
        })
      }
      const response = await fetch(`${process.env.REACT_APP_BPN_ADMIN_USERS}/register`, requestOptions);
      const result = await response.json();
      setMsgError(result.message)
      // navigate('/dashboard-admin');
      return result;
    } catch (error) {
      throw error;
    }
  }
  return (
    <Layout>
      <div className="bg-slate-100 xl:w-[80rem] w-[100%] mx-auto p-5 rounded-md">
      <div className="w-full max-w-3xl mx-auto my-10">
      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
      <p className="text-2xl mb-5">Tambah Pengguna</p>
      
      <hr className="mb-5" />
      <p className="mb-4 ">{msgError && msgError}</p>
          <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" for="username">
                  Nama
              </label>
              <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" onChange={(e) => setNama(e.target.value)} id="name" type="text" placeholder="Masukkan Nama..." />
          </div>
          <div class="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" for="username">
                  Alamat Email
              </label>
              <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" onChange={(e) => setEmail(e.target.value)} id="email" type="text" placeholder="Masukkan Alamat Email..." />
          </div>
          <div class="mb-4">
              <label class="block text-gray-700 text-sm font-bold mb-2" for="username">
                  Password
              </label>
              <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" onChange={(e) => setPassword(e.target.value)} id="wa" type="password" placeholder="Masukkan Password..." />
          </div>
          <div class="mb-4">
              <label class="block text-gray-700 text-sm font-bold mb-2" for="username">
                  Konfirmasi Password
              </label>
              <input class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" onChange={(e) => setConfPassword(e.target.value)} id="wa" type="password" placeholder="Konfirmasi Password" />
          </div>
          <button className="bg-slate-500 hover:bg-slate-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full mt-2" onClick={submitDataForm}>
              Tambah
          </button>
      </div>

      </div>
  </div>
    </Layout>
  )
}

export default TambahUserAdmin