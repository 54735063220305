import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import GetCookie from '../hooks/getCookie'
import Layout from './Layout'
import AlurPelaporan from '../components/AlurPelaporan'
import ImageSlider from '../components/ImageSlider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAddressCard, faObjectGroup } from '@fortawesome/free-regular-svg-icons'
import { faEye, faVideo } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faInstagram, faTwitter, faYoutube, faWhatsapp, faGoogle, faGooglePlus  } from '@fortawesome/free-brands-svg-icons'
const Home = () => {
  const navigate = useNavigate();
  const checkToken = GetCookie('token');

  useEffect(() => {
    if(checkToken) navigate("/dashboard-admin")
  })

  return (
    <Layout>
      <ImageSlider />
      <div className="2xl:w-[80rem] max-w-screen-lg xl:px-0 px-10 mx-auto py-16">
        <h2 className="text-3xl font-semibold">Selamat datang di BPN Tangerang selatan bagian Laporan Pengaduan</h2>
        <p className="font-light my-2 text-lg">Untuk melakukan <strong>Laporan Pengaduan</strong>, Anda dapat datang langsung ke kantor atau dapat menghubungi kami melalui Sosial Media.</p>
      </div>
      <hr />
      <div className="2xl:w-[80rem] max-w-screen-xl mx-auto sm:px-0 px-10">
        <div className="flex xl:justify-between my-[5rem] xl:flex-row flex-col xl:items-start items-center">
          <div className="home-visi xl:w-[25rem] sm:w-[40rem] w-full flex">
            <div className="border h-[4rem] w-[4rem] mr-5 rounded-full">
              <ul className="social-media pelayanan mt-[0.4rem] mr-1">
                <li><a><FontAwesomeIcon icon={faAddressCard} className="icon"></FontAwesomeIcon></a></li>
              </ul>
            </div>
            <div>
              <p className="text-lg font-semibold">Melayani</p>
              <p className="my-3">Seluruh aparatur memiliki prinsip melayani yakni:</p>
              <p>Melayani dengan kejelasan prosedur, biaya, dan ketepatan waktu</p>
              <p className="my-3">Bersikap sopan, ramah, cermat dan teliti serta peduli terhadap lingkungan pelayanan.</p>
            </div>
          </div>
          <div className="home-visi xl:w-[25rem] sm:w-[40rem] w-full xl:my-0 my-10 flex">
            <div className="border h-[4rem] w-[4rem] mr-5 rounded-full">
              <ul className="social-media pelayanan mt-[0.4rem] mr-1">
                <li><a><FontAwesomeIcon icon={faEye} className="icon"></FontAwesomeIcon></a></li>
              </ul>
            </div>
            <div>
              <p className="text-lg font-semibold">PROFESIONALISME</p>
              <p className="my-3">Setiap aparatur memiliki jiwa profesionalisme seperti:</p>
              <p>Bekerja sama, bekerja cerdas, tuntas dan memberikan nilai tambah</p>
              <p className="my-3">Senantiasa mengembangkan diri untuk peningkatan kompetensi dan pendidikan</p>
            </div>
            
          </div>
          <div className="home-visi xl:w-[25rem] sm:w-[40rem] w-full flex">
            <div className="border h-[4rem] w-[4rem] mr-5 rounded-full">
              <ul className="social-media pelayanan mt-[0.4rem] mr-1">
                <li><a><FontAwesomeIcon icon={faObjectGroup} className="icon"></FontAwesomeIcon></a></li>
              </ul>
            </div>
            <div>
              <p className="text-lg font-semibold">TERPERCAYA</p>
              <p className="my-3">Aparatur diharapkan menjadi insan terpercaya yakni:</p>
              <p>Bekerja dengan integritas, dapat dipercaya dan diandalkan, menjaga martabat serta tidak melakukan hal tercela.</p>
              <p className="my-3">Patuh dan taat pada peraturan yang ditetapkan sesuai tugas dan tanggung jawab yang diberikan.</p>
            </div>
          </div>
        </div>
        <div className="w-full lobby-kantor container flex items-center justify-center h-[20vh] m-auto mb-20 bg-fixed bg-center bg-cover custom-img">
          <h4 className="text-center text-3xl font-extrabold text-amber-500">"BPN TANGERANG SELATAN SIAP MELAYANI DENGAN SEPENUH HATI."</h4>
          
        </div>
        <hr className="mb-[10rem]" />
        <div>
          <h2 className="text-center text-3xl font-bold">TENTANG LAPORAN PENGADUAN</h2>
          <hr className="my-10 w-[4rem] mx-auto h-[0.2rem] bg-black" />
          <div className="flex justify-between xl:flex-row flex-col xl:items-start items-center mt-10">
            <div className="xl:w-[25rem] sm:w-[40rem] w-full">
              <h4 className="text-xl font-semibold">APA ITU <span className="text-[#1ABC9C]">PENGADUAN</span>.</h4>
              <hr className="bg-[#1ABC9C] h-[0.2rem] my-4" />
              <p className="leading-8 mt-8">Pengaduan adalah penyampaian keluhan yang disampaikan oleh pengadu kepada pengelola pengaduan pelayanan publik atas pelayanan pelaksana yang tidak sesuai dengan Standar Pelayanan atau Pengabaian kewajiban dan/atau pelanggaran larangan oleh Penyelenggara.</p>
            </div>
            <div className="xl:w-[25rem] sm:w-[40rem] w-full xl:my-0 my-10">
              <h4 className="text-xl font-semibold">TUJUAN DARI <span className="text-[#1ABC9C]">PENGADUAN</span>.</h4>
              <hr className="bg-[#1ABC9C] h-[0.2rem] my-4" />
              <p className="leading-8 mt-8">Untuk menyelesaikan keluhan - keluhan masyarakat terhadap pelayanan yang ada di Kantor ATR/BPN Tangerang Selatan.</p>
            </div>
            <div className="xl:w-[25rem] sm:w-[40rem] w-full">
              <h4 className="text-xl font-semibold">PELAYANAN <span className="text-[#1ABC9C]">PENGADUAN</span>.</h4>
              <hr className="bg-[#1ABC9C] h-[0.2rem] my-4" />
              <p className="leading-8 mt-8">Pengaduan yang dikelola melalui aplikasi SP4N-LAPOR! dan/atau pengelola pengaduan Kementrian / Lembaga, Pemerintah Daerah, Korporasi dan Badan Hukum lainnya disampaikan melalui : Tatap muka, call-center, email, sms, Media sosial, website</p>
              <ul className="social-media mt-6 grid sm:grid-cols-8 grid-cols-4">
                <li><a href="https://www.facebook.com/kantah.bpntangsel"><FontAwesomeIcon icon={faFacebook} className="icon"></FontAwesomeIcon></a></li>
                <li><a href="https://twitter.com/KantahTangsel"><FontAwesomeIcon icon={faTwitter} className="icon"></FontAwesomeIcon></a></li>
                <li><a href="https://www.youtube.com/channel/UCMGM-L4ajPea9A8q6uw6bCw"><FontAwesomeIcon icon={faYoutube} className="icon"></FontAwesomeIcon></a></li>
                <li><a href="https://www.instagram.com/kantahtangsel"><FontAwesomeIcon icon={faInstagram} className="icon"></FontAwesomeIcon></a></li>
                <li><a href="https://api.whatsapp.com/send?phone=+6281315555010&text=Nama Pemohon:%0aNo Hp :%0aAlamat Lengkap Pemohon:%0aPengaduan lengkap:%0a(Sertakan No Hak, Foto Tanda Terima, atau Berkas Terkait)"><FontAwesomeIcon icon={faWhatsapp} className="icon"></FontAwesomeIcon></a></li>
                <li><a href="https://mail.google.com/mail/?view=cm&fs=1&to=kot-tangerangselatan@atrbpn.go.id&su=Laporan Pengaduan&body=Nama Pemohon:%0aNo Hp :%0aAlamat Lengkap Pemohon:%0aPengaduan lengkap:%0a(Sertakan No Hak, Foto Tanda Terima, atau Berkas Terkait)"><FontAwesomeIcon icon={faGoogle} className="icon"></FontAwesomeIcon></a></li>
                <li><a href="https://us04web.zoom.us/j/2954013673?pwd=BPNtangsel"><FontAwesomeIcon icon={faVideo} className="icon"></FontAwesomeIcon></a></li>
                <li><a href="https://www.google.com/search?q=google+review+bpn+tangsel&rlz=1C5CHFA_enID940ID940&sxsrf=AOaemvLkAEg9xoPNr120fvrruCjSYh0NHA%3A1632386160880&ei=cDxMYbyTNbrS1sQPpNWO0A4&oq=google+review+bpn+tangsel&gs_lcp=Cgdnd3Mtd2l6EAM6BwgAEEcQsAM6BwgAELADEEM6BQgAEIAEOgUIABDLAToLCC4QxwEQrwEQywE6BwgAEAoQywE6BQghEKABOgcIIRAKEKABOggIIRAWEB0QHjoECCEQFUoECEEYAFC0HljzWmCnXGgJcAJ4AYABugWIAbQXkgEKMC4xMy4yLjUtMZgBAKABAcgBCsABAQ&sclient=gws-wiz&ved=0ahUKEwi8i4Wq2JTzAhU6qZUCHaSqA-oQ4dUDCA0&uact=5"><FontAwesomeIcon icon={faGooglePlus} className="icon"></FontAwesomeIcon></a></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="flex justify-center my-20 items-center">
          <hr className="w-[20rem]" />

          <div className="bg-slate-300 w-[1rem] h-[1rem] rounded-full mx-6"></div>

          <hr className="w-[20rem]" />
        </div>

        <div>
          <h2 className="text-center text-3xl font-bold">ALUR LAPORAN PENGADUAN</h2>
          <hr className="my-10 w-[4rem] mx-auto h-[0.2rem] bg-black" />
          <img src={require("../images/alur-pengaduan.jpeg")} alt="alur pengaduan" className="w-[30rem] mx-auto" />
        </div>
        <AlurPelaporan />
      </div>
    </Layout>
    
  )
}

export default Home