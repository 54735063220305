import './App.css';
import { Route, Routes, useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import GetCookie from './hooks/getCookie';
import Home from "./pages/Home";
import Layanan from "./pages/Layanan"
import CheckAntrian from './pages/CheckAntrian';
import About from './pages/About';
import PenarikanNik from './pages/PenarikanNik';
import TambahUserAdmin from './pages/TambahUserAdmin';
import LoginAdmin from './pages/LoginAdmin';
import Dashboard from './pages/Dashboard';
import AntrianSuccess from './pages/AntrianSuccess';
import UploadSuratKuasa from './pages/UploadSuratKuasa';
import ProtectedRoutes from './Routes/ProtectedRoutes';
import Logout from './pages/Logout';
import JumlahLaporan from './pages/JumlahLaporan';
import TanggalSpesial from './pages/TanggalSpesial';

function App() {

  //check token
  //if valid
  // get api
  // response
  // dispatch context 
  // userContext

  const [tokenCheck, setTokenCheck] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    setTokenCheck(GetCookie('token'))
    // if(tokenCheck) console.log('yay')
    // else console.log('nay');
  }, [])

  return (
    <Routes>
      {/* Public */}
      <Route path="/" element={<Home />} />
      {/* <Route path="/detail/:id" element={<Detail />} /> */}

      {/* sampe jam 1 */}
      <Route path="/layanan" element={<Layanan />} />
      <Route path="/about" element={<About />} />
      <Route path="/login" element={<LoginAdmin />} />
      <Route path="/antrian-success/:id" element={<AntrianSuccess />} />
      <Route path="/check-antrian/:id" element={<CheckAntrian />} />
      {/* Admin */}
      {/* <ProtectedRoutes path="/dashboard-admin" component={<Dashboard />} /> */}
      {/* <Route
        render={(props) => {
          const token = GetCookie('token');

          if (token) {
            return <Dashboard {...props} />;
          } else {
            return (
              navigate("/")
            );
          }
        }}
      /> */}
      {/* {tokenCheck ? 
        <>
          <Route path="/dashboard-admin" element={<Dashboard />} />
          <Route path="/penarikan-data-nik" element={<PenarikanNik />} />
          <Route path="/upload-surat-kuasa" element={<UploadSuratKuasa />} />
          <Route path="/tambah-user-admin" element={<TambahUserAdmin />} />
        </>
        
        : navigate('/')
      } */}
      <Route path="/dashboard-admin" element={<Dashboard />} />
      <Route path="/penarikan-data-nik" element={<PenarikanNik />} />
      <Route path="/upload-surat-kuasa" element={<UploadSuratKuasa />} />
      <Route path="/edit-jumlah-laporan" element={<JumlahLaporan />} />
      <Route path="/tambah-user-admin" element={<TambahUserAdmin />} />
      <Route path="/tanggal-spesial" element={<TanggalSpesial />} />
      <Route path="/logout" element={<Logout />} />
    </Routes>
  );
}

export default App;
