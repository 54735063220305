import React, { useEffect } from 'react';
// import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
// import { Bar, Line } from "react-chartjs-2";
import { 
  ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, Legend
} from 'recharts';

const BarChartInfo = ({ data }) => {
  let jenisData = '';

  if (data?.map(e => e.id.includes("T"))) jenisData = "Pengambilan"
  else if (data?.map(e => e.id.includes("R"))) jenisData = "Pendaftaran"
  
  // let jenisData = '';
  // console.log(data && data)
  const nameChanged = data?.map((e) => {return e.nama.replace(jenisData, "")})
  return (
    <div className="flex justify-center">
      <ResponsiveContainer width="100%" height={400}>

      
      <BarChart width={730} height={250} data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="nama" fontSize={13} />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="count" fill="#8884d8" />
        {/* <Bar dataKey="uv" fill="#82ca9d" /> */}
      </BarChart>
      </ResponsiveContainer>
      {/* <Line
        data={{
          labels: data?.map((e) => {return e.nama.replace(jenisData, "")}),
          // labels: jenisData,
          datasets: [
            {
              // label: jenisData,
              label: "Jumlah Laporan",
              data: data?.map((e) => {return e.count}),
              backgroundColor: [
                'rgba(228, 113, 30, 0.7)',
                'rgba(218, 147, 96, 0.5)',
                'rgba(34, 170, 189, 0.5)',
                'rgba(189, 34, 34, 0.7)',

                'rgba(230, 113, 30, 0.7)',
                'rgba(218, 147, 96, 0.5)',
                'rgba(34, 170, 189, 0.5)',
                'rgba(189, 34, 34, 0.7)',

                'rgba(228, 113, 30, 0.7)',
                'rgba(218, 147, 96, 0.5)',
                'rgba(34, 170, 189, 0.5)',
                'rgba(189, 34, 34, 0.7)',
                'rgba(189, 34, 34, 0.7)',
              ],
              borderColor: [
                'rgba(228, 113, 30, 1)',
                'rgba(218, 147, 96, 1)',
                'rgba(34, 170, 189, 1)',
                'rgba(189, 34, 34, 1)',
              ],
              borderWidth: 1,
            },
          ],
        }}
      /> */}
    </div>
  );
};



export default BarChartInfo;