/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import Select from 'react-select'
import Layout from './Layout'
import DatePicker from 'react-datepicker'
import { getJumlahLaporanBpn, getMasterConfig } from '../services/data'
import GetCookie from '../hooks/getCookie';
import { addDays } from 'date-fns';
import NumberFormat from 'react-number-format';
import id from "date-fns/locale/id";


const JumlahLaporan = () => {
  const [lapWa, setLapWa] = useState();
  const [lapIg, setLapIg] = useState();
  const [lapEmail, setLapEmail] = useState();
  const [lapFb, setLapFb] = useState();
  const [lapZoom, setLapZoom] = useState();
  const [lapSelesai, setLapSelesai] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [errorMsg, setErrorMsg] = useState();
  const [dataLaporan, setDataLaporan] = useState();

  const [dataMasterConfig, setDataMasterConfig] = useState();
  

  const navigate = useNavigate();


  const checkToken = GetCookie('token');
  useEffect(() => {
    // refreshToken()
    if(!checkToken) navigate("/")
  }, )

  useEffect(() => {
    getLaporanBpn();
    getMasterConfigData();
  },[])

  const getLaporanBpn = async () => {
    // console.log(startDate)
    const data = await getJumlahLaporanBpn();
    if(data.data) {
      setErrorMsg(null);
      setDataLaporan(data);
    } 
  }

  const submitDataForm = async () => {
    const requestOptions = {
      method: 'PUT',
      headers :{'Content-Type':'application/json'},
      body: JSON.stringify({
        laporan_whatsapp: lapWa,
        laporan_instagram: lapIg,
        laporan_email: lapEmail,
        laporan_facebook: lapFb,
        laporan_zoom: lapZoom,
        laporan_selesai: lapSelesai,
        last_updated: startDate,
      })
    }
    if (!lapWa) return setErrorMsg("Laporan Whatsapp harus terisi.")
    else if(isNaN(lapWa)) return setErrorMsg("Laporan Whatsapp harus berisi angka.")
    else if(!lapIg) return setErrorMsg("Laporan Instagram harus terisi.")
    else if(isNaN(lapIg)) return setErrorMsg("Laporan Instagram harus berisi angka.")
    else if(!lapEmail) return setErrorMsg("Laporan Email harus terisi.")
    else if(isNaN(lapEmail)) return setErrorMsg("Laporan Email harus berisi angka.")
    else if(!lapFb) return setErrorMsg("Laporan Facebook harus terisi.")
    else if(isNaN(lapFb)) return setErrorMsg("Laporan Facebook harus berisi angka.")
    else if(!lapZoom) return setErrorMsg("Laporan Zoom harus terisi.")
    else if(isNaN(lapZoom)) return setErrorMsg("Laporan Zoom harus berisi angka.")
    else if(!lapSelesai) return setErrorMsg("Laporan Terselesaikan harus terisi.")
    else if(isNaN(lapSelesai)) return setErrorMsg("Laporan Terselesaikan harus berisi angka.")
    else if(!startDate) return setErrorMsg("Tanggal harus terisi.")
    else {
      try{
        if (confirm("Apakah anda yakin untuk mengubah jumlah laporan yang ada?")) {
          const response = await fetch(process.env.REACT_APP_BPN_JUMLAH_LAPORAN, requestOptions);
          const result = await response.json();
          // console.log(result)
          if (result.statusCode === "000") {
            // navigate('/edit-jumlah-laporan/');
            window.location.reload()
          }else{
            setErrorMsg(result.message)
          }
        }
        
        
      }catch(e) {
        throw e
      }
    }
  }

  // PENGATURAN FITUR FORM LAYANAN
  const getMasterConfigData = async () => {
    // console.log(startDate)
    const data = await getMasterConfig();
    if(data.data) {
      setErrorMsg(null);
      setDataMasterConfig(data.data);
    } 
  }

  const [batasAntrian, setBatasAntrian] = useState();
  const [flagLayanan, setFlagLayanan] = useState();

  const filterLayananActive = dataMasterConfig?.find((e) => {
    return e.config_name === "layanan_active"
  })

  // setBatasAntrian(filterLayananActive?.value)

  const filterBatasAntrian = dataMasterConfig?.find((e) => {
    return e.config_name === "batas_antrian"
  })


  const flagLayananOption = [
    {
      value: "0",
      label: "Non-Aktif",
    },
    {
      value: "1",
      label: "Aktif"
    }
  ]

  const handleFlagLayanan = value => {
    try {
      setFlagLayanan(value.value);
    } catch (error) {
      console.log(error);
    }
  };

  const submitDataFlagLayanan = async () => {
    if (!flagLayanan){
      return setErrorMsg("Mohon input ulang Fitur Form sebelum menyimpan pengaturan ini.")
    } 
    else if (!batasAntrian) return setErrorMsg("Mohon input ulang Batas Antrian sebelum menyimpan pengaturan ini.")
    else{
      const requestOptions = {
        method: 'PUT',
        headers :{'Content-Type':'application/json'},
        body: JSON.stringify({
          layanan_active: flagLayanan,
          batas_antrian: batasAntrian
        })
      }

      try{
        if (confirm("Apakah anda yakin untuk mengubah pengaturan Fitur Layanan ini?")) {
          const response = await fetch(process.env.REACT_APP_BPN_MASTER_CONFIG, requestOptions);
          const result = await response.json();
          // console.log(result)
          if (result.statusCode === "000") {
            // navigate('/edit-jumlah-laporan/');
            window.location.reload()
          }else{
            setErrorMsg(result.message)
          }
        }
      }catch(e) {
        throw e
      }
    }
  }

  return (
    <Layout>
      <div className="max-w-screen-lg mx-auto sm:px-0 px-5">
        <h4 className="text-center my-[2rem] font-semibold text-2xl">Edit Fitur Layanan</h4>
    
        <div className={`bg-slate-50 pt-5 sm:px-20 px-5 rounded-lg my-5`}>
          <div className="mb-4">
            <p className="text-[17px] mb-2">
              Fitur Form Layanan
            </p>
            <Select
              onChange={handleFlagLayanan}
              // styles={styles}
              options={flagLayananOption}
              defaultValue={filterLayananActive?.value === "1" ? "Aktif" : "Non-Aktif"}
              placeholder={filterLayananActive?.value === "1" ? "Aktif" : "Non-Aktif"}
            />
          </div>
          <div className="mt-3">
            <p className="text-[17px]">
              Batas Antrian
            </p>
            <NumberFormat
              displayType="number"
              thousandSeparator={true}
              onValueChange={(values) => {
                const { formattedValue, value } = values;
                setBatasAntrian(value);
              }}
              className="my-1 border-y-2 border-x-2 rounded-md px-2 py-1 w-full"
              placeholder={filterBatasAntrian?.value}
              defaultValue={filterBatasAntrian?.value}
            />
          </div>
          <div className={`my-4 border rounded-sm p-3 ${errorMsg ? "block" : "hidden"}`}>
            <p className="text-red-600">{errorMsg}</p>
          </div>
          <div className="flex justify-end">
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline my-5" type="button" onClick={submitDataFlagLayanan}>
              Simpan
            </button>
          </div>
        </div>
      </div>

      <div className="max-w-screen-lg mx-auto sm:px-0 px-5">
        <h4 className="text-center my-[2rem] font-semibold text-2xl">Edit Jumlah Laporan</h4>
        <div className="my-10 text-center">
          <div className="grid grid-cols-2 gap-7">
            <div className="border-b-2 p-2">
              <p className="text-xl font-light">Laporan Whatsapp</p>
              <p>{dataLaporan?.data?.laporan_whatsapp}</p>
            </div>
            <div className="border-b-2 p-2">
              <p className="text-xl font-light">Laporan Instagram</p>
              <p>{dataLaporan?.data?.laporan_instagram}</p>
            </div>
            <div className="border-b-2 p-2">
              <p className="text-xl font-light">Laporan Email</p>
              <p>{dataLaporan?.data?.laporan_email}</p>
            </div>
            <div className="border-b-2 p-2">
              <p className="text-xl font-light">Laporan Facebook</p>
              <p>{dataLaporan?.data?.laporan_facebook}</p>
            </div>
            <div className="border-b-2 p-2">
              <p className="text-xl font-light">Laporan Zoom</p>
              <p>{dataLaporan?.data?.laporan_zoom}</p>
            </div>
            <div className="border-b-2 p-2">
              <p className="text-xl font-light">Laporan Terselesaikan</p>
              <p>{dataLaporan?.data?.laporan_terselesaikan}</p>
            </div>
            
          </div>
          <div className="border-b-2 p-2 my-2">
              <p className="text-xl font-light">Last Updated</p>
              <p>{dataLaporan?.data?.last_updated}</p>
            </div>
          
          {/* <h3 className="my-2">{errorMsg && errorMsg}</h3> */}
        </div>
        
        <div className={`bg-slate-50 pt-5 sm:px-20 px-5 rounded-lg my-5`}>
          <p className="text-xl font-semibold">Form Update Laporan BPN</p>
          <hr className="my-5"/>
          <div className="">
            <p className="text-[17px]">
              Laporan Whatsapp 
            </p>
            <NumberFormat
              displayType="number"
              thousandSeparator={true}
              onValueChange={(values) => {
                const { formattedValue, value } = values;
                setLapWa(value);
              }}
              className="my-1 border-y-2 border-x-2 rounded-md px-2 py-1 w-full"
              placeholder="Input laporan whatsapp"
            />
          </div>
          <div className="mt-3">
            <p className="text-[17px]">
              Laporan Instagram 
            </p>
            <NumberFormat
              displayType="number"
              thousandSeparator={true}
              onValueChange={(values) => {
                const { formattedValue, value } = values;
                setLapIg(value);
              }}
              className="my-1 border-y-2 border-x-2 rounded-md px-2 py-1 w-full"
              placeholder="Input laporan instagram"
            />
          </div>
          <div className="mt-3">
            <p className="text-[17px]">
              Laporan Email 
            </p>
            <NumberFormat
              // prefix="Rp."
              displayType="number"
              thousandSeparator={true}
              onValueChange={(values) => {
                const { formattedValue, value } = values;
                setLapEmail(value);
              }}
              className="my-1 border-y-2 border-x-2 rounded-md px-2 py-1 w-full"
              placeholder="Input laporan email"
            />
          </div>
          <div className="mt-3">
            <p className="text-[17px]">
              Laporan Facebook 
            </p>
            <NumberFormat
              displayType="number"
              thousandSeparator={true}
              onValueChange={(values) => {
                const { formattedValue, value } = values;
                setLapFb(value);
              }}
              className="my-1 border-y-2 border-x-2 rounded-md px-2 py-1 w-full"
              placeholder="Input laporan facebook"
            />
          </div>
          <div className="mt-3">
            <p className="text-[17px]">
              Laporan Zoom 
            </p>
            <NumberFormat
              displayType="number"
              thousandSeparator={true}
              onValueChange={(values) => {
                const { formattedValue, value } = values;
                setLapZoom(value);
              }}
              className="my-1 border-y-2 border-x-2 rounded-md px-2 py-1 w-full"
              placeholder="Input laporan zoom"
            />
          </div>
          <div className="mt-3">
            <p className="text-[17px]">
              Laporan Terselesaikan 
            </p>
            <NumberFormat
              displayType="number"
              thousandSeparator={true}
              onValueChange={(values) => {
                const { formattedValue, value } = values;
                setLapSelesai(value);
              }}
              className="my-1 border-y-2 border-x-2 rounded-md px-2 py-1 w-full"
              placeholder="Input laporan terselesaikan"
            />
          </div>
          <div className="my-3">
            <p className="text-[17px]">
              Update Per Tanggal: 
            </p>
            <DatePicker 
              locale={id}
              className="w-full border-zinc-200 border-x-2 border-y-2 p-[0.4rem] rounded-md"
              selected={startDate} onChange={(date) => setStartDate(date)} 
              dateFormat="d MMMM yyyy"
              includeDateIntervals={[{start: addDays(new Date(), -30), end: addDays(new Date(), 0) }]}
            />
          </div>
          <div className={`my-4 border rounded-sm p-3 ${errorMsg ? "block" : "hidden"}`}>
            <p className="text-red-600">{errorMsg}</p>
          </div>
          <div className="flex justify-end">
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline my-5" type="button" onClick={submitDataForm}>
              Update Jumlah Laporan
            </button>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default JumlahLaporan