import React, { useState, useEffect } from 'react'
import Layout from './Layout'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import SetCookie from '../hooks/setCookie'
import GetCookie from '../hooks/getCookie'

const LoginAdmin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [msgError, setMsgError] = useState('');
  const navigate = useNavigate();
  const checkToken = GetCookie('token')
  useEffect(() => {
    if(checkToken) navigate('/dashboard-admin')
  })

  const submitDataForm = async (e) => {
    e.preventDefault()
    // try {
    //   console.log(email, password)
    //   const requestOptions = {
    //     method: 'POST',
    //     headers :{'Content-Type':'application/json'},
    //     body: JSON.stringify({
    //       email: email,
    //       password: password,
    //     })
    //   }
    //   const response = await fetch(`${process.env.REACT_APP_BPN_ADMIN_USERS}/login`, requestOptions);
    //   const result = await response.json();
    //   console.log(result)
      
    //   return result;
    // } catch (error) {
    //   alert("goblok")
    //   setMsgError(error)
    //   throw error;
    // }
    axios.defaults.withCredentials = true;
    try {
      const res = await axios.post(`${process.env.REACT_APP_BPN_ADMIN_USERS}/login`, {
        email: email,
        password: password
      });
      SetCookie('token', res.data.data.accessToken);
      // return res.data
      navigate("/dashboard-admin")
    } catch (error) {
      setMsgError(error.response.data.message);
      // throw error
    }
  }
  return (
    <Layout>
      <div className="bg-slate-100 xl:w-[80rem] w-[100%] mx-auto p-5 rounded-md my-20">
      <div className="w-full max-w-3xl mx-auto my-10">
      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
      <p className="text-2xl mb-5">Login</p>
      
      <hr className="mb-5" />
      <p className="mb-4 text-red-700">{msgError && msgError}</p>
          <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
                  Alamat Email
              </label>
              <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" onChange={(e) => setEmail(e.target.value)} id="email" type="text" placeholder="Masukkan Alamat Email..." />
          </div>
          <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
                  Password
              </label>
              <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" onChange={(e) => setPassword(e.target.value)} id="wa" type="password" placeholder="Masukkan Password..." />
          </div>
          <button className="bg-slate-500 hover:bg-slate-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full mt-2" onClick={submitDataForm}>
              Login
          </button>
      </div>

        </div>
      </div>
    </Layout>
  )
}

export default LoginAdmin