/* eslint-disable no-restricted-globals */
import React, { useState } from 'react'
import moment from 'moment';
const KolomTanggalLibur = ({ data }) => {
  console.log(data)
  const [errorMsg, setErrorMsg] = useState();
  // const date = moment()
  const submitDataForm = async () => {
    const requestOptions = {
      method: 'DELETE',
      headers :{'Content-Type':'application/json'},
      body: JSON.stringify({
        id: data?.id,
      })
    }
    if (!data.id) return setErrorMsg("ID harus terisi.")

    else {
      try{
        if (confirm("Apakah anda yakin untuk MENGHAPUS tanggal libur ini?")) {
          const response = await fetch(process.env.REACT_APP_BPN_TANGGAL_SPESIAL, requestOptions);
          const result = await response.json();
          // console.log(result)
          if (result.statusCode === "000") {
            // navigate('/edit-jumlah-laporan/');
            window.location.reload()
          }else{
            setErrorMsg(result.message)
          }
        }
        
        
      }catch(e) {
        throw e
      }
    }
  }

  return (
    <tr className="bg-slate-50 border-b  dark:border-gray-700">
      <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap">
        {moment(data?.tanggal_spesial).format('ll')}
      </th>
      <td className="py-4 px-6 text-gray-900">
        {data?.description}
      </td>
      <td className="py-4 px-6">
        <button className="bg-red-500 hover:bg-red-700 text-white font-light py-2 px-4 rounded focus:outline-none focus:shadow-outline my-5" onClick={submitDataForm}>Delete</button>
        <p className="text-red-600">{errorMsg}</p>
      </td>
    </tr>
  )
}

export default KolomTanggalLibur