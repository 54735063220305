import React from 'react'

const Maintenance = () => {
  return (
    <div className="m-10">
      <h2 className="text-center text-2xl font-semibold">Maaf, Fitur Layanan sedang dalam Maintenance</h2>
      <img className="w-[20rem] mx-auto my-5" src={require("../images/maintenance.png")} alt="" />
      <p className="text-center">Silahkan hubungi petugas atau tunggu beberapa saat lagi :)</p>
    </div>
  )
}

export default Maintenance