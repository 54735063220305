/* eslint-disable array-callback-return */
import Select from 'react-select'
import Calendar from 'react-calendar'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { validationEmail } from '../util/utilFunction';
import { addDays, parseISO } from 'date-fns';
import id from "date-fns/locale/id";
import NumberFormat from 'react-number-format';
import moment from 'moment';

import { useState } from 'react'
let indoTime = new Date().toLocaleString("en-US", {timeZone: "Asia/Jakarta"});

const FormLayanan = ({ layanan }) => {
  const [jenisPendaftar, setJenisPendaftar] = useState(null);
  const [name, setName] = useState('');
  const [nik, setNik] = useState('');
  const [email, setEmail] = useState('');
  const [nowa, setNowa] = useState('');
  const [jenisPendaftaran, setJenisPendaftaran] = useState([]);
  const [jenisPengambilan, setJenisPengambilan] = useState([]);
  const [inputKodePengambilan, setInputKodePengambilan] = useState();
  const [kodePengambilan, setKodePengambilan] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [checkbox, setCheckbox] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const navigate = useNavigate();
  // const kodePengambilan = []
  const filteredPendaftaran = layanan?.data?.filter((item) => {
    return item.layanan_id.includes('R')
  })
  const filteredPengambilan = layanan?.data?.filter((item) => {
    return item.layanan_id.includes('T')
  })


  const jenisPendaftarOption = [
    {
      value: 0,
      label: "Kuasa",
      isDisabled: (moment(new Date(startDate)).format('dddd') !== "Minggu" && moment(new Date(startDate)).format('dddd') !== "Sabtu" ? false : true)
    },
    {
      value: 1,
      label: "Pemohon Langsung"
    }
  ]

  const optionsPendaftaran = (filteredPendaftaran || []).map(pendaftaran => ({
    value: pendaftaran?.layanan_id,
    label: pendaftaran?.nama_layanan,
  }));

  const optionsPengambilan = (filteredPengambilan || []).map((pengambilan) => ({
    value: pengambilan?.layanan_id,
    label: pengambilan?.nama_layanan,
  }));

  const handleJenisPendaftar = value => {
    try {
      setJenisPendaftar(value.value);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePendaftaranChange = value => {
    try {
      setJenisPendaftaran(value);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePengambilanChange = value => {
    // value[value.length - 1].inputKodePengambilan = ''
    // console.log(jenisPengambilan)
    // console.log(value)
    if (value.length !== 0 && value.length > jenisPengambilan.length) {
      value[value.length - 1].kode_pengambilan = ''
      if (jenisPengambilan.length > 0) {
        for (let i = 0; i < value.length - 1; i++) {
          value[i].kode_pengambilan = jenisPengambilan[i].kode_pengambilan
        }
      }
    }
    try {
      setJenisPengambilan(value);
    } catch (error) {
      console.log(error);
    }
  };

  // const handleKodePengambilan = value => {
  //   try {
  //     console.log(value)
  //     setJenisPengambilan(jenisPengambilan => 
  //       jenisPengambilan.map((v, i) => {
  //         if (i === value) {
  //           v.kode_pengambilan.push(v.inputKodePengambilan)
  //           return v.inputKodePengambilan = "";
  //         }
  //       })
  //     )

  //     // setKodePengambilan(kodePengambilan => [...kodePengambilan, inputKodePengambilan])
  //     // setInputKodePengambilan(() => "")
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  const removeKodePengambilan = value => {
    setKodePengambilan((kodePengambilan) => kodePengambilan.filter((e, i) => i !== value))
  }

  const handleCheckboxForm = event => {
    if (event.target.checked) {
      setCheckbox(true)
    } else {
      setCheckbox(false)
    }
  }

  const isWeekday = (date) => {
    const day = date.getDay();
    if (jenisPendaftar === 0) {
      
      return day !== 0 && day !== 6;
    }
  };

  const dynamicInput = (e) => {
    const inputName = e.target.name
    const val = e.target.value
    // const detail = jenisPengambilan.find(i => i.value === inputName)
    const detail = jenisPengambilan[inputName]
    const filterState = jenisPengambilan.filter(v => v.value !== inputName)
    let temp_state = [...jenisPengambilan];
    // console.log(temp_state)
    let temp_element = { ...temp_state[inputName] };
    temp_element.kode_pengambilan = val
    temp_state[inputName] = temp_element;
    setJenisPengambilan(temp_state)
    // console.log(temp_state)

    // let pengambilan = jenisPengambilan.map((e) => {
    //   if (e.value === inputName) {
    //     e.kode_pengambilan = val
    //   }
    // })
    // console.log(filterState)
    // detail.kode_pengambilan = val
    // filterState.push(detail)
    // console.log(detail)

    // setJenisPengambilan(filterState)
    // setJenisPengambilan(jenisPengambilan => jenisPengambilan[inputName] = detail)

    // const fix = [{...detail, val}]
    // detail['val'] = val;
    // setInputKodePengambilan(inputKodePengambilan => [...inputKodePengambilan, {value: }])} 
    // console.log({fix})

    // setJenisPengambilan(prev => )

    // console.log({
    //   val: e.target.value,
    //   name: e.target.name,
    //   detail,
    //   fixedArr
    // })
  }

  const submitDataForm = async () => {
    let transaction = [];
    let flagQuantity = 0;
    if (jenisPendaftaran.length > 0 || jenisPengambilan.length > 0) {
      Array.prototype.push.apply(transaction, jenisPendaftaran); 
      Array.prototype.push.apply(transaction, jenisPengambilan);
    }
    // console.log(jenisPendaftar)
    // return
    const requestOptions = {
      method: 'POST',
      headers :{'Content-Type':'application/json'},
      body: JSON.stringify({
        jenisPendaftar: jenisPendaftar,
        name: name,
        nik: nik,
        email: email,
        whatsapp_num: nowa,
        // regist_id: jenisPendaftaran || null,
        // take_id: jenisPengambilan || null,
        transaction: transaction,
        booking_time: startDate,
      })
    }
    // console.log(transaction)
    for (let i of transaction) {
      if (i.value.includes("R")){
        if (i.quantity === 0 || i.quantity < 0 || i.quantity === undefined) flagQuantity = 1;
      } else if (i.value.includes("T")) {
        if (i.kode_pengambilan === '' || i.kode_pengambilan === undefined) flagQuantity = 1;
      }
      
    }
    // console.log(flagQuantity)
    if (jenisPendaftar === null) return setErrorMsg("Jenis Pendaftar harus terisi.")
    else if (!name) return setErrorMsg("Nama harus terisi.")
    else if(!nik) return setErrorMsg("NIK harus terisi.")
    else if(isNaN(nik)) return setErrorMsg("NIK harus berisi angka.")
    else if(!email) return setErrorMsg("Email harus terisi.")
    else if(!validationEmail(email)) return setErrorMsg("Email harus valid.")
    else if(!nowa) return setErrorMsg("Nomor telepon harus terisi.")
    else if(isNaN(nowa)) return setErrorMsg("Nomor telepon harus berisi angka.")
    else if(jenisPendaftaran.length < 1 && jenisPengambilan.length < 1) return setErrorMsg("Jenis Pendaftaran atau Jenis Pengambilan harus terisi.")
    else if(flagQuantity === 1) return setErrorMsg("Mohon isi Jumlah Surat / ID Pengambilan dengan benar.")
    else if(!startDate) return setErrorMsg("Tanggal harus terisi.")
    else if(checkbox === false) return setErrorMsg("Persyaratan harus disetujui.")
    
    else {
      setErrorMsg('');
      try{
        const response = await fetch(process.env.REACT_APP_BPN_ANTRIAN, requestOptions);
        const result = await response.json();
        // console.log(result)
        if (result.statusCode === "000") {
          navigate(`/antrian-success/${result.data.antrian_id}`, {state: result.data});
          return result;
        }else{
          setErrorMsg(result.message)
        }
        
      }catch(e) {
        throw e
      }
    }
  }
  return (
    <div className="bg-slate-100 xl:w-[80rem] w-[100%] mx-auto p-5 rounded-md mb-10">
      <div className="w-full max-w-3xl mx-auto my-10">
      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
      <p className="text-2xl mb-5">Form Layanan</p>
      
      <hr className="mb-5" />
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Jenis Pendaftar
            </label>
            <Select
              onChange={handleJenisPendaftar}
              // styles={styles}
              options={jenisPendaftarOption}
            />
          </div>
          <div className="mb-4">
              <label className="sm:flex block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                  Nama <aside className="text-red-500 sm:ml-3 ml-0 text-xs">(Masukkan Nama sesuai dengan KTP)</aside>
              </label>
              <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" onChange={(e) => setName(e.target.value)} id="name" type="text" placeholder="Masukkan Nama..." />
          </div>
          <div className="mb-4">
              <label className="sm:flex block text-gray-700 text-sm font-bold mb-2" htmlFor="nik">
                  NIK <aside className="text-red-500 sm:ml-3 ml-0 text-xs">(Masukkan NIK sesuai dengan KTP)</aside>
              </label>
              <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" onChange={(e) => setNik(e.target.value)} id="nik" type="text" placeholder="Masukkan NIK..." />
          </div>
          <div className="mb-4">
              <label className="sm:flex block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                  Alamat Email <aside className="text-red-500 sm:ml-3 ml-0 text-xs">(Masukkan Email dengan benar)</aside>
              </label>
              <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" onChange={(e) => setEmail(e.target.value)} id="email" type="text" placeholder="Masukkan Alamat Email..." />
          </div>
          <div className="mb-4">
              <label className="sm:flex block text-gray-700 text-sm font-bold mb-2" htmlFor="wa">
                  NO TELEPON <aside className="text-red-500 sm:ml-3 ml-0 text-xs">(Masukkan Nomor Telepon aktif dengan benar)</aside>
              </label>
              <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" onChange={(e) => setNowa(e.target.value)} id="wa" type="text" placeholder="Masukkan Nomor Telepon..." />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="pendaftaran">
              Pilih Jenis Pendaftaran
            </label>
            <Select
              onChange={handlePendaftaranChange}
              isMulti
              // styles={styles}
              options={optionsPendaftaran}
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="pengambilan">
              Pilih Jenis Pengambilan
            </label>
            <Select
              onChange={handlePengambilanChange}
              isMulti
              // styles={styles}
              options={optionsPengambilan}
            />
          </div>
          {
            jenisPendaftaran.length > 0 ? (
              <div className="mb-4">
                <label className="block text-gray-700 text-lg font-bold mb-2" htmlFor="pengambilan">
                  Jumlah Surat Pendaftaran
                </label>
                <div>
                  {jenisPendaftaran?.map((e, i) => {
                    // return console.log(e)
                    return (
                      <div key={i}>
                        <p>- {e?.label}</p>
                        <NumberFormat
                          displayType="number"
                          thousandSeparator={true}
                          onValueChange={(values) => {
                            const { formattedValue, value } = values;
                            jenisPendaftaran[i].quantity = value
                          }}
                          className="my-1 border-y-2 border-x-2 rounded-md px-2 py-1 w-full"
                          placeholder="Input Jumlah"
                        />
                      </div>
                    )
                  })}
                </div>
              </div>
            ) : ''
          }
          {
            jenisPengambilan.length > 0 ? (
              <div className="mb-4">
                <label className="block text-gray-700 text-lg font-bold " htmlFor="pengambilan">
                  Nomor ID Pengambilan
                </label>
                <p className="text-sm mb-2">*ID Pengambilan didapatkan dari Whatsapp kantor pertanahan</p>
                <div>
                  {jenisPengambilan?.map((e, i) => {
                    // console.log(e)
                    return (
                      <div className="mt-3" key={i}>
                        <p>- {e?.label}</p>
                        {/* {e.kode_pengambilan?.map((e, i) => 
                          (<li key={i}>{e} <button className="bg-red-500 hover:bg-red-700 text-white font-bold px-1 mt-2 rounded focus:outline-none focus:shadow-outline" onClick={() => 
                            // kodePengambilan.shift(i)
                            removeKodePengambilan(i)
                          }>x</button></li>)
                        ) ?? ''} */}
                        {/* <input className="shadow appearance-none border rounded w-full mt-1 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" onChange={(e) => jenisPengambilan[i].kode_pengambilan = e.target.value} type="text" placeholder="ID Pengambilan (Batasi setiap ID menggunakan koma ',')" /> */}
                        <p className="text-red-700 text-xs">* Pisahkan antar ID dengan tanda Koma ','</p>
                        <p className="text-red-700 text-xs font-semibold">Contoh: (XX12345,XY23456)</p>
                        <input className="shadow appearance-none border rounded w-full mt-2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" onChange={dynamicInput} type="text" placeholder="Input ID Pengambilan" name={i} />
                        {/* <input className="shadow appearance-none border rounded w-full mt-2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" onChange={(v) => setInputKodePengambilan(inputKodePengambilan => [...inputKodePengambilan, {value: }])} type="text" placeholder="Input ID Pengambilan" value={e.inputKodePengambilan} /> */}
                        {/* 
                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 mt-2 rounded focus:outline-none focus:shadow-outline" onClick={() => handleKodePengambilan(i)}>Add</button> */}
                      </div>
                    )
                  })}
                </div>
              </div>
            ) : ''
          }
          <div className="mb-4">
            <label className="sm:flex block text-gray-700 text-sm font-bold mb-2" htmlFor="tanggal">
              Pilih Tanggal <aside className="text-red-500 sm:ml-3 ml-0 text-xs">(Diluar hari libur nasional)</aside>
            </label>
            <DatePicker 
              locale={id}
              className="w-full border-zinc-200 border-x-2 border-y-2 p-[0.4rem] rounded-md"
              selected={startDate} onChange={(date) => setStartDate(date)} 
              dateFormat="d MMMM yyyy"
              // minDate={moment(new Date())}
              filterDate={jenisPendaftar === 0 ? isWeekday : null}
              minDate={new Date()}
              includeDateIntervals={[{start: addDays(new Date(), -1), end: addDays(new Date(), 30) }]}
            />
          </div>
          
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="surat">
              Dapatkan Formulir Lampiran 13 Dan Kuasa (Apabila Di Kuasakan)
            </label>
            <div>
              <a href={process.env.REACT_APP_BPN_UPLOAD_SURAT_13} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button" download>
                  Lampiran 13 dan Kuasa
              </a>
            </div>
          </div>
          <div className="col-12 bottommargin-sm">
            <div className="form-check">
              <input className="form-check-input" type="checkbox" id="template-contactform-mobile" name="template-contactform-checkbox[]" value="Mobile" onChange={handleCheckboxForm} />
              {/* <a href="https://drive.google.com/file/d/1Fodc6R1vTiKHPaZpDUIiue0RzpaqETjX/view?usp=sharing" className="ml-2" target="_blank" rel="noreferrer">Baca persyaratan pendaftaran & pengambilan</a> */}
              <a href="https://docs.google.com/document/d/1jp4UuTHE0ltaxmSrdZtfCd0DcKEAYiP9/edit?usp=sharing&ouid=110359866457483306675&rtpof=true&sd=true" className="ml-2" target="_blank" rel="noreferrer">Baca persyaratan pendaftaran & pengambilan</a>
            </div>
          </div>
          <div className={`my-4 border rounded-sm p-3 ${errorMsg ? "block" : "hidden"}`}>
            <p className="text-red-600">{errorMsg}</p>
          </div>
          <button className="bg-slate-500 hover:bg-slate-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full mt-2" onClick={submitDataForm}>
            Send
          </button>
      </div>

      </div>
  </div>
  )
}

export default FormLayanan