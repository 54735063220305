import React, { useEffect } from 'react'
import RemoveCookie from '../hooks/removeCookie'
import { useNavigate } from 'react-router-dom'

const Logout = () => {
  const navigate = useNavigate();
  useEffect(() => {
    RemoveCookie('token')
    navigate('/');
  })
}

export default Logout