import React, { useState, useEffect } from 'react'
import { harusDisiapkan, caraPertama, caraKedua, caraKetiga, caraKeempat } from '../hardcode/AlurLaporanPengaduan'

const AlurPelaporan = () => {
  const [langkah, setLangkah] = useState(harusDisiapkan);

  return (
    <div className="flex justify-between 2xl:w-[80rem] lg:w-[65rem] my-16 sm:px-10 lg:flex-row flex-col">
      <div className="flex flex-col lg:w-[20rem] w-full border rounded-lg">
        <button className={`text-start p-3 border border-slate-100' ${langkah.id === 1 ? 'bg-[#1ABC9C] text-white' : ''}`} onClick={(() => setLangkah(harusDisiapkan))}>Yang harus disiapkan</button>
        <button className={`text-start p-3 border border-slate-100' ${langkah.id === 2 ? 'bg-[#1ABC9C] text-white' : ''}`} onClick={(() => setLangkah(caraPertama))}>Langkah Pertama</button>
        <button className={`text-start p-3 border border-slate-100' ${langkah.id === 3 ? 'bg-[#1ABC9C] text-white' : ''}`} onClick={(() => setLangkah(caraKedua))}>Langkah Kedua</button>
        <button className={`text-start p-3 border border-slate-100' ${langkah.id === 4 ? 'bg-[#1ABC9C] text-white' : ''}`} onClick={(() => setLangkah(caraKetiga))}>Langkah Ketiga</button>
        <button className={`text-start p-3 border border-slate-100' ${langkah.id === 5 ? 'bg-[#1ABC9C] text-white' : ''}`} onClick={(() => setLangkah(caraKeempat))}>Langkah Keempat</button>
      </div>
      <div className="lg:w-[50rem] w-full ease-in-out duration-300 md:mt-0 mt-5 lg:ml-10">
        <h2 className="text-2xl font-bold">{langkah.title}</h2>
        {langkah.subTitle ? <h4 className="text-lg font-extralight mt-6">{langkah.subTitle}</h4> : ""}
        <hr className="w-[4rem] h-[0.2rem] bg-black my-6" />
        <p className="leading-8 my-6">{langkah.description}</p>
        {
          langkah.listCara ? langkah.listCara.map((e) => {
            return <li key={e.id}>{e.cara}</li>
          }) : ""
        }
      </div>
    </div>
  )
}

export default AlurPelaporan