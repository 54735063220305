import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Layout from './Layout'
import FormLayanan from '../components/FormLayanan'
import { useEffect } from 'react'
import { getAllLayanan, getMasterConfig } from '../services/data'
import GetCookie from '../hooks/getCookie'
import Maintenance from '../components/Maintenance'

const Layanan = () => {
  const [layanan, setLayanan] = useState([])
  const [alertActive, setAlertActive] = useState(true);

  const [dataMasterConfig, setDataMasterConfig] = useState();

  const navigate = useNavigate();
  const checkToken = GetCookie('token');

  useEffect(() => {
    if (checkToken) navigate('/dashboard-admin');

  })

  useEffect(() => {
    getAllLayananData();
    getMasterConfigData();
  }, [])

  const getAllLayananData = async () => {
    const data = await getAllLayanan();
    setLayanan(data)
  }

  const getMasterConfigData = async () => {
    // console.log(startDate)
    const data = await getMasterConfig();
    if(data.data) {
      setDataMasterConfig(data.data);
    } 
  }
  const filterLayananActive = dataMasterConfig?.find((e) => {
    return e.config_name === "layanan_active"
  })

  return (
    <Layout>
      {filterLayananActive?.value == 1 ? 
        (
          <div className={`${alertActive ? 'bg-[rgba(0,0,0,0.4)]' : ''}`}>
            <div className={`bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md z-1 ${alertActive ? 'block' : 'hidden'}`} role="alert">
              <div className="flex">
                <div className="py-1"><svg class="fill-current h-6 w-6 text-teal-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
                <div>
                  <p className="font-bold text-xl">Mohon Dibaca Sebelum Mendaftar Antrian</p>
                  <ul>
                    <li className="text-lg">- Pastikan sebelum mendaftar untuk memahami dan melengkapi berkas sesuai persyaratan dan ketentuan yang berlaku</li>
                    <li className="text-lg">- Untuk konsultasi masalah kelengkapan berkas, dapat juga melalui Zoom Meeting SULTAN TANGSEL</li>
                    <li className="text-lg">- Bagi pemohon yang tidak dikuasakan, Pelayanan Pendaftaran Roya dan Perubahan Hak, dapat juga dilakukan di Kantor Mall Pelayanan Publik (MPP) Tangerang Selatan</li>
                  </ul>
                  <button className="bg-lime-300 hover:bg-lime-400 py-1 px-3 mt-5 mb-2 rounded-sm" onClick={() => setAlertActive(false)}>Tutup untuk menampilkan Form Layanan</button>
                </div>
              </div>
            </div>
            <div className="xl:w-[80rem] lg:w-[60rem] mx-auto py-10">
              <h2 className="text-center text-3xl font-bold">JENIS LAYANAN PERTANAHAN</h2>
              <hr className="w-10 mx-auto border-2 border-stone-900 my-3" />
              <div className="flex justify-evenly md:flex-row flex-col py-10 md:p-0 p-5">
                <div className="xl:w-[35rem]">
                  <div className="my-4">
                    <h2 className="text-center text-3xl font-sm">Pendaftaran</h2> 
                    <hr className="w-10 mx-auto border-1 border-stone-900 my-3" />
                  </div>
                  
                  <p className="text-center my-4 leading-8">Anda dapat melakukan antrian pendaftaran secara online, kantor offline dibuka pada pukul.</p>
                  <p className="text-center my-4">08.00 WIB - 12.00 WIB</p>
                </div>
                <div className="xl:w-[35rem]">
                  <div className="my-4">
                    <h2 className="text-center text-3xl font-sm">Pengambilan</h2>
                    <hr className="w-10 mx-auto border-1 border-stone-900 my-3" />
                  </div>
                  
                  <p className="text-center my-4 leading-8">Anda dapat melakukan antrian pengambilan secara online, kantor offline dibuka pada pukul.</p>
                  <p className="text-center my-4">13.00 WIB - 15.00 WIB</p>
                </div>
              </div>
              <div className="xl:w-[35rem] mx-auto mt-16 mb-10">
                <div className="my-4">
                  <h2 className="text-center text-3xl font-sm">Pelataran (Pelayanan Tanah Akhir Pekan)</h2>
                  <hr className="w-10 mx-auto border-1 border-stone-900 my-3" />
                </div>
                
                <p className="text-center my-4 leading-8">PEMOHON LANGSUNG dapat melakukan antrian pendaftaran & pengambilan secara online, kantor offline PELATARAN dibuka pada pukul.</p>
                <p className="text-center my-4">08.00 WIB - 12.00 WIB</p>
              </div>
            </div>
            {alertActive ? 
              null 
              :
              <FormLayanan layanan={layanan} />
            }
          </div>
        ) :
        <Maintenance />
      }
      
    </Layout>
  )
}

export default Layanan