// import Layout from './Layout'
import React from 'react'
import { useLocation } from 'react-router-dom';
import { antrianPendaftaran, antrianPengambilan, antrianPendaftaranPengambilan } from '../hardcode/dataLayananChart'
import { useNavigate } from 'react-router-dom';
import { QRCodeSVG } from 'qrcode.react';
import axios from 'axios';

const ETicket = React.forwardRef((props, ref) => {
  const navigate = useNavigate();

  const { state } = useLocation();
  const { antrian_id, booking_time, email, nama, nik, no_antrian, no_telp, transaction, jenis_pendaftar } = state;
  // let stringKodePengambilan = ''
  // console.log(antrian_id)
  // console.log(state)
  let jenisPendaftar = jenis_pendaftar === 0 ? "Kuasa" : jenis_pendaftar === 1 ? "Pemohon Langsung" : null
  for (const v of transaction) {
    const cariNamaAntrian = antrianPendaftaranPengambilan.filter((e) => {
      return e.id === v.layanan_type
    })
    v.layanan_name = cariNamaAntrian[0]?.nama
    // if (v.layanan_type.includes("T")) {
    //   // v.kode_pengambilan = JSON.parse(v.kode_pengambilan)
    //   // v.kode_pengambilan.replace("[", "")
    //   for (let i of v.kode_pengambilan) {
    //     if (stringKodePengambilan !== '') stringKodePengambilan += `, ${i}`
    //     else stringKodePengambilan += i
    //   }
    // }
  }
  return (
    // <Layout>
			<div class="content-wrap bg-light flex justify-center">
				<div class="sm:w-[50rem] w-full mt-10 shadow border rounded bg-slate-50" ref={ref}>
					<div class="row justify-content-center">
						<div class="col-lg-7 col-md-10">
							<div class="card my-5 shadow-sm">
								<div class="card-body p-5">
                  <div class="col-sm-3 center">
                    <img src={require("../images/bpn-logo.png")} className="w-[12rem] ml-5" alt="bpn_tangsel" />
                  </div>

									<h2 class="font-body uppercase text-center text-3xl font-semibold">E-Tiket</h2>
									<h4 class="font-body text-uppercase text-center text-xl font-medium my-6">Layanan BPN Tangsel</h4>
									<h6 class="font-secondary text-center text-sm font-light"> Melayani, Profesional, Terpecaya </h6>

                  <div className="flex justify-center my-6 items-center">
                    <hr className="w-[10rem]" />

                    <div className="bg-slate-300 w-[1rem] h-[1rem] rounded-full mx-6"></div>

                    <hr className="w-[10rem]" />
                  </div>
									
									<div class="form-widget w-full" data-alert-type="false">

										<div class="form-process">
											<div class="css3-spinner">
												<div class="css3-spinner-double-bounce1"></div>
												<div class="css3-spinner-double-bounce2"></div>
											</div>
										</div>

                    <div class="row mx-auto">
                      <div class="col-12 center mb-5">
                        <h4 class="font-body text-uppercase text-center text-xl font-medium">Antrian</h4>
                        <h4 class="font-secondary text-uppercase text-center text-xl font-medium my-5">{no_antrian}</h4>
                        <h4 className="font-body text-uppercase text-center text-xl font-medium">Save your Date</h4>
                        <h4 className="font-secondary text-uppercase text-center text-xl font-medium my-5">{booking_time}</h4>
                      </div>
                      <div className="flex justify-center mb-6">
                        <QRCodeSVG 
                          value={`${window.location.origin}/check-antrian/${antrian_id}`}
                          size={150}
                        />
                      </div>
                      <div className="col-12 w-full">
                        <div className="row">
                          <table className="w-[80%] md:ml-20 sm:ml-10 ml-5">
                            <tbody>
                            <tr>
                              <td>Nama</td>
                              <td>:</td>
                              <td>{nama}</td>
                            </tr>
                            <tr><td>NIK</td>
                              <td>:</td>
                              <td>{nik}</td>
                            </tr>
                            <tr>
                              <td>Nomor Telepon</td>
                              <td>:</td>
                              <td>{no_telp}</td>
                            </tr>
                            <tr>
                              <td>Email</td>
                              <td>:</td>
                              <td>{email}</td>
                            </tr>
                            <tr>
                              <td>Jenis Pendaftar</td>
                              <td>:</td>
                              <td>{jenisPendaftar}</td>
                            </tr>
                            <tr><td>Layanan</td>
                              <td>:</td>
                              {/* <td>{cariNamaPendaftaran[0]?.nama}</td> */}
                              {/* <td>tes</td>
                              <td>wkwk</td> */}
                            </tr>
                            {transaction?.map((e) => {
                                let stringKodePengambilan = ''
                                if (e.layanan_type.includes("T")) {
                                  // v.kode_pengambilan = JSON.parse(v.kode_pengambilan)
                                  // v.kode_pengambilan.replace("[", "")
                                  for (let i of e.kode_pengambilan) {
                                    if (stringKodePengambilan !== '') stringKodePengambilan += `, ${i}`
                                    else stringKodePengambilan += i
                                  }
                                }
                              return (
                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td>- {e?.layanan_name} <strong>({e?.quantity === 0 ? stringKodePengambilan : e?.quantity})</strong></td>
                                </tr>
                              )
                            })}
                            {/* {flagPengambilan === 1 ? (
                              <tr><td>Kode Pengambilan</td>
                                <td>:</td>
                              </tr>
                                {transaction?.map((e) => {
                                  return (
                                    <tr>
                                      <td></td>
                                      <td></td>
                                      <td>- {e?.layanan_name} <strong>({e?.quantity})</strong></td>
                                    </tr>
                                  )
                                })}
                              )
                              : ''
                            } */}
                            </tbody>
                          </table>
                        </div>
                      </div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
    // </Layout>
    
  )
});

export default ETicket