import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import Layout from "./Layout"
import ImageSlider from "../components/ImageSlider"
import GetCookie from "../hooks/getCookie"

const About = () => {
  const navigate = useNavigate();
  const checkToken = GetCookie('token');
  
  useEffect(() => {
    if (checkToken) navigate("/dashboard-admin"); 
  })

  return (
    <Layout>
      <ImageSlider />
      <div className="xl:w-[80rem] lg:w-[60rem] w-[80%] my-20 mx-auto">
        <h2 className="text-center text-3xl font-bold">BPN Tangerang Selatan</h2>
        <hr className="w-10 mx-auto h-[0.2rem] bg-stone-900 my-10" />
        <p className="leading-8">Kota Tangerang Selatan mempunyai luas sekitar 16.485,47 Hektar dari luas wilayah Provinsi Banten (sumber Peraturan Daerah Kota Tangerang Selatan Nomor 9 Tahun 2019 tentang Perubahan Atas Peraturan Daerah Nomor 15 Tahun 2011 tentang Rencana Tata Ruang Wilayah Kota Tangerang Selatan Tahun 2011-2031) Kota Tangerang Selatan 2016-2021) terdiri dari 7 Kecamatan dan 54 Kelurahan. Adapun Jumlah Bidang Tanah di Kota Tangerang Selatan yang sudah terdaftar kurang lebih 443.079 bidang. Kota Tangerang Selatan terletak di bagian timur Provinsi Banten yaitu pada titik koordinat 106°38’ – 106°47’ Bujur Timur dan 06°13’30” – 06°22’30” Lintang Selatan.</p>
        <div className="flex justify-center my-20 items-center">
          <hr className="w-[20rem]" />

          <div className="bg-slate-300 w-[1rem] h-[1rem] rounded-full mx-6"></div>

          <hr className="w-[20rem]" />
        </div>
        <div className="flex justify-between my-10 lg:flex-row flex-col">
          <div className="lg:w-[35rem] w-auto">
            <div className="my-4">
              <h2 className="text-center text-3xl">Visi</h2>
              <hr className="w-10 mx-auto h-[0.2rem] bg-stone-900 my-3" />
            </div> 
            <p className="my-4 leading-8">Terwujudnya Penataan Ruang dan Pengelolaan Pertanahan yang Terpercaya dan Berstandar Dunia dalam Melayani Masyarakat untuk mendukung tercapainya Indonesia Maju yang Berdaulat, Mandiri, Berkepribadian Berdasarkan Gotong Royong.</p>
          </div>
          <div className="lg:w-[35rem] w-auto">
            <div className="my-4">
              <h2 className="text-center text-3xl">Misi</h2>
              <hr className="w-10 mx-auto h-[0.2rem] bg-stone-900 my-3" />
            </div>
            <p className="my-4 leading-8">Menyelenggarakan Penataan Ruang dan Pengelolaan Pertanahan yang Produktif, Berkelanjutan dan Berkeadilan Menyelenggarakan Pelayanan Pertanahan dan Penataan Ruang yang Berstandar Dunia.</p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default About