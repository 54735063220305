export const getAllLayanan = async () => {
  try {
    const res = await fetch(process.env.REACT_APP_BPN_LAYANAN)
    const data = await res.json();
    return data;
  } catch (error) {
    console.log(error)
    alert("Data failed to be fetched.")
  }
}
  
export const getAntrianDataByDate = async (date) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_BPN_ANTRIAN}/recap-bydate?booking_time=${date}`)
    const data = await res.json();
    return data;
  } catch (error) {
    console.log(error)
    alert("Data failed to be fetched.")
  }
}

export const getAntrianDataByDateRange = async (jenis = "R", startDate = new Date(), endDate = new Date()) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_BPN_ANTRIAN}/recap?jenis_layanan=${jenis}&start_date=${startDate}&end_date=${endDate}`)
    const data = await res.json();
    return data;
  } catch (error) {
    console.log(error)
    alert("Data failed to be fetched.")
  }
}

export const getAntrianDataById = async (antrian_id) => {
  try {
    const res = await fetch(`${process.env.REACT_APP_BPN_ANTRIAN}/get-antrian-id?antrian_id=${antrian_id}`)
    const data = await res.json();
    return data;
  } catch (error) {
    console.log(error)
    alert("Data failed to be fetched.")
  }
}

export const getJumlahLaporanBpn = async () => {
  try {
    const res = await fetch(`${process.env.REACT_APP_BPN_JUMLAH_LAPORAN}`)
    const data = await res.json();
    return data;
  } catch (error) {
    console.log(error)
    alert("Data failed to be fetched.")
  }
}

export const getMasterConfig = async () => {
  try {
    const res = await fetch(`${process.env.REACT_APP_BPN_MASTER_CONFIG}`)
    const data = await res.json();
    return data;
  } catch (error) {
    console.log(error)
    alert("Data failed to be fetched.")
  }
}

export const getTanggalLibur = async () => {
  try {
    const res = await fetch(`${process.env.REACT_APP_BPN_TANGGAL_SPESIAL}`)
    const data = await res.json();
    return data;
  } catch (error) {
    console.log(error)
    alert("Data failed to be fetched.")
  }
}