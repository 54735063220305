import React from 'react'
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Layout from './Layout';
import GetCookie from '../hooks/getCookie';


const UploadSuratKuasa = () => {
  const [filesKuasa13, setFilesKuasa13] = useState([]);
  const [fileNameKuasa13, setFileNameKuasa13] = useState("");

  const [filesKuasa14, setFilesKuasa14] = useState([]);
  const [fileNameKuasa14, setFileNameKuasa14] = useState("");

  const navigate = useNavigate();


  const checkToken = GetCookie('token');
  useEffect(() => {
    // refreshToken()
    if(!checkToken) navigate("/")
  }, )

    const onInputChange13 = (e) => {
      setFilesKuasa13(e.target.files[0])
      setFileNameKuasa13(e.target.files[0].name)
    };

    const onInputChange14 = (e) => {
      setFilesKuasa14(e.target.files[0])
      setFileNameKuasa14(e.target.files[0].name)
    };

    const onSubmit13 = (e) => {
      const data = new FormData();
      data.append('file', filesKuasa13);
      data.append('fileName', fileNameKuasa13)

      const res = axios.post(process.env.REACT_APP_BPN_UPLOAD_SURAT_13, data)
        .then((response) => {
          console.log(res)
          alert("Success Upload")
        })
        .catch((e) => {
          console.log(e)
          alert('Upload Error')
        })
    };
    const onSubmit14 = (e) => {
      const data = new FormData();
      data.append('file', filesKuasa14);
      data.append('fileName', fileNameKuasa14)

      const res = axios.post(process.env.REACT_APP_BPN_UPLOAD_SURAT_14, data)
        .then((response) => {
          console.log(res)
          alert("Success Upload")
        })
        .catch((e) => {
          console.log(e)
          alert('Upload Error')
        })
    };

  return (
    <Layout>
      <div className="w-[80%] mx-auto my-[2rem] flex flex-col">
        <h4 className="text-center my-[2rem] font-semibold text-2xl">Upload Surat Kuasa</h4>
        <div className="flex lg:flex-row flex-col my-16">
          <div>
            <div className="form-group files flex flex-col">
              <label className="block text-gray-700 text-xl font-semibold mb-4">Lampiran 13 dan Kuasa</label>
              <input type="file" onChange={onInputChange13} className="block sm:w-[20rem] w-[15rem] border rounded-lg text-sm text-slate-500
                file:mr-4 file:py-2 file:px-4
                file:border-0
                file:text-sm file:font-semibold
                file:bg-violet-50 file:text-green-700
                hover:file:bg-violet-100" multiple
              />
            </div>

            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-[7rem] my-5" onClick={onSubmit13}>Submit</button>
          </div>
          {/* <div className="lg:ml-28 ml-0">
            <div className="form-group files flex flex-col">
              <label className="block text-gray-700 text-xl font-semibold mb-4">Surat Kuasa 14</label>
              <input type="file" onChange={onInputChange14} className="block sm:w-[20rem] w-[15rem] border rounded-lg text-sm text-slate-500
                file:mr-4 file:py-2 file:px-4
                file:border-0
                file:text-sm file:font-semibold
                file:bg-violet-50 file:text-green-700
                hover:file:bg-violet-100" multiple
              />
            </div>

            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-[7rem] my-5" onClick={onSubmit14}>Submit</button>
          </div> */}
        </div>
        
        
      </div>
    </Layout>
  )
}

export default UploadSuratKuasa