import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faTwitter, faYoutube, faWhatsapp, faGoogle  } from '@fortawesome/free-brands-svg-icons'
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import moment from "moment";
import 'moment/locale/id'
import { useLocation } from "react-router-dom";
import { getJumlahLaporanBpn } from "../services/data"

const Footer = () => {
  const [jumlahLaporan, setJumlahLaporan] = useState();
  const [activeWa, setActiveWa] = useState(true);
  const [activeIg, setActiveIg] = useState(true)
  const [activeFb, setActiveFb] = useState(true)
  const [activeEmail, setActiveEmail] = useState(true)
  const [activeAll, setActiveAll] = useState(true)
  const [activeZoom, setActiveZoom] = useState(true)
  const location = useLocation();

  
  useEffect(() => {
    getJumlah();
  },[])

  const getJumlah = async () => {
    const data = await getJumlahLaporanBpn();
    setJumlahLaporan(data)
  }

  const handleSensorWa = (num) => {
      // if (isNaN(api)) return <p>{api}</p>
    return <CountUp end={num} duration={2} onStart={() => setActiveWa(false) } />;
  };
   const handleSensorIg = (num) => {
    // if (isNaN(api)) return <p>{api}</p>
    return <CountUp end={num} duration={2} onStart={() => setActiveIg(false) } />;
  };
  const handleSensorFb = (num) => {
    // if (isNaN(api)) return <p>{api}</p>
    return <CountUp end={num} duration={2} onStart={() => setActiveFb(false) } />;
  };
  const handleSensorEmail = (num) => {
    // if (isNaN(api)) return <p>{api}</p>
    return <CountUp end={num} duration={2} onStart={() => setActiveEmail(false) } />;
  };
  const handleSensorAll = (num) => {
    // if (isNaN(api)) return <p>{api}</p>
    return <CountUp end={num} duration={2} onStart={() => setActiveAll(false) } />;
  };
  const handleSensorZoom = (num) => {
    // if (isNaN(api)) return <p>{api}</p>
    return <CountUp end={num} duration={2} onStart={() => setActiveZoom(false) } />;
  };

  return (
    <footer className="py-[3rem] md:px-3 px-5 text-[#eee]">
      <div className="flex lg:flex-row flex-col justify-between lg:w-[80%] w-[100%] mx-auto">
        <div className="lg:w-[70%] w-[100%]">
          <h4>Jalankan Program Prioritas di Masa Pandemi,</h4>
          <h4>BPN Tangerang Selatan, Fokus memberikan kepastian atas tanah Masyarakat.</h4>
          <hr className="mt-10 opacity-25" />
          {location.pathname === "/" ? 
            (<div className="flex justify-between sm:flex-row flex-col mt-5">
            <div>
              <p>Total Laporan Pengaduan Per Tanggal {moment(jumlahLaporan?.data?.last_updated).locale('id').format('LL')}</p>
              <div className="mt-5 grid grid-cols-2 place-items-center">
                <div className="w-[6rem] text-center">
                  <VisibilitySensor active={activeWa}>
                    {({ isVisible }) => (
                      <p className="text-[1.8rem] my-1 font-bold text-[#35BBAA]">
                        {isVisible ? handleSensorWa(jumlahLaporan?.data?.laporan_whatsapp) : 0}
                      </p>
                    )}
                  </VisibilitySensor>
                  <p className="text-[0.8rem]">LAPORAN DI WHATSAPP</p>
                </div>
                <div className="w-[6rem] text-center">
                  <VisibilitySensor active={activeIg}>
                    {({ isVisible }) => (
                      <p className="text-[1.8rem] my-1 font-bold text-[#2CAACA]">
                        {isVisible ? handleSensorIg(jumlahLaporan?.data?.laporan_instagram) : 0}
                      </p>
                    )}
                  </VisibilitySensor>
                  <p className="text-[0.8rem]">LAPORAN DI INSTAGRAM</p>
                </div>
                <div className="w-[6rem] text-center">
                  <VisibilitySensor active={activeFb}>
                    {({ isVisible }) => (
                      <p className="text-[1.8rem] my-1 font-bold text-[#2CAACA]">
                        {isVisible ? handleSensorFb(jumlahLaporan?.data?.laporan_facebook) : 0}
                      </p>
                    )}
                  </VisibilitySensor>
                  <p className="text-[0.8rem]">LAPORAN DI FACEBOOK</p>
                </div>
                <div className="w-[6rem] text-center">
                  <VisibilitySensor active={activeEmail}>
                    {({ isVisible }) => (
                      <p className="text-[1.8rem] my-1 font-bold text-[#2CAACA]">
                        {isVisible ? handleSensorEmail(jumlahLaporan?.data?.laporan_email) : 0}
                      </p>
                    )}
                  </VisibilitySensor>
                  <p className="text-[0.8rem]">LAPORAN DI EMAIL</p>
                </div>
              </div>
              <div className="w-[6rem] text-center mx-auto mt-5">
                <VisibilitySensor active={activeAll}>
                    {({ isVisible }) => (
                      <p className="text-[1.8rem] my-1 font-bold text-[#2CAACA]">
                        {isVisible ? handleSensorAll(jumlahLaporan?.data?.laporan_terselesaikan) : 0}
                      </p>
                    )}
                  </VisibilitySensor>
                <p className="text-[0.8rem]">LAPORAN PENGADUAN TERSELESAIKAN</p>
              </div>
            </div>
            <div className="sm:mt-0 mt-10">
              <p>Total Laporan Sultan Zoom Per Tanggal {moment(jumlahLaporan?.data?.last_updated).locale('id').format('LL')}</p>
              <VisibilitySensor active={activeZoom}>
                {({ isVisible }) => (
                  <p className="text-[1.8rem] my-1 text-center font-bold text-[#2CAACA]">
                    {isVisible ? handleSensorZoom(jumlahLaporan?.data?.laporan_zoom) : 0}
                  </p>
                )}
              </VisibilitySensor>
            </div>
          </div>)
            : ''
          }
          
        </div>
        <div className="flex flex-col lg:mt-0 mt-5 ml-5">
          <h4>LOKASI KANTOR</h4>
          <p className="text-sm my-5 leading-6">Jl. Letnan Soetopo Kav No 2 Lingkar Timur BSD, <br />Rawa Mekar Jaya Serpong <br />Kota Tangerang Selatan</p>
          <div className="text-sm leading-6">
            <p>Phone: +62 81315555010</p>
            <p>Email: kot-tangerangselatan@atrbpn.go.id</p>
            <ul className="footer-socmed mt-6">
              <li><a href="https://twitter.com/KantahTangsel"><FontAwesomeIcon icon={faTwitter} className="icon"></FontAwesomeIcon></a></li>
              <li><a href="/"><FontAwesomeIcon icon={faFacebook} className="icon"></FontAwesomeIcon></a></li>
              <li><a href="https://www.youtube.com/channel/UCMGM-L4ajPea9A8q6uw6bCw"><FontAwesomeIcon icon={faYoutube} className="icon"></FontAwesomeIcon></a></li>
              <li><a href="/"><FontAwesomeIcon icon={faInstagram} className="icon"></FontAwesomeIcon></a></li>
            </ul>
          </div>
        </div>
        
      </div>
      <div className="w-[70%] mx-auto mt-20">
        <p className="text-[#eee] opacity-40">Copyrights &copy; 2021 All Rights Reserved by BPN Tangerang Selatan.</p>
      </div>
    </footer>
  )
}

export default Footer